<div class="dlv-table w-full h-full" *ngIf="cols">
  <table class="egger-dlv-table relative -top-16">
    <tr class="decor-data">
      <th class="empty-cell">
        <div class="inline m-2 absolute top-0 left-0 lg:top-1.5 lg:left-1.5">
          <button class="mt-2 ml-1 button-table tooltip p-0.5 rounded-full leading-3">
            <mat-icon svgIcon="questionmark" class="inline w-5 h-5 m-1 ml-0.5 text-red-600"></mat-icon>
          </button>
          <span class="hidden lg:inline text-sm text-black relative -top-3 font-normal"></span>
        </div>
        <button class="hidden lg:block button-table text-red-600 p-2 text-sm bg-red-100 rounded-full absolute bottom-0 right-0 m-4">
          <mat-icon svgIcon="arrow-long" class="inline ml-1 relative text-red-600 arrow"></mat-icon>
          <span class="button-text -top-px text-xs mx-1 relative font-normal"></span>
        </button>
      </th>
      <ng-container *ngFor="let col of cols">
        <th class="headline-decor h-40">
          <div class="float-left mt-3">
            <div class="headline-decor-icon inline-block w-6 h-6 mb-2 mx-3 border border-solid border-black"></div>
            <span class="text-sm inline relative left-1">...</span>
          </div>
        </th>
      </ng-container>
    </tr>

    <tbody>
      <ng-container *ngFor="let col of cols">
        <tr class="product-data">
          <td class="headline-product cursor-pointer pr-0 w-80">
            <div class="hidden-text relative h-6"></div>
          </td>
          <ng-container *ngFor="let col of cols">
            <td class="border-inside availability-icon"></td>
          </ng-container>
        </tr>
      </ng-container>
      <ng-container *ngFor="let col of cols">
        <tr class="product-data">
          <td class="headline-product cursor-pointer pr-0 w-80">
            <div class="hidden-text relative h-6"></div>
          </td>
          <ng-container *ngFor="let col of cols">
            <td class="border-inside availability-icon"></td>
          </ng-container>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
