import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import {
  DcuplFacetOptions,
  DcuplQuery,
  DcuplQueryBuilder,
  DcuplQueryGroup,
  PivotOptions,
  PivotResponse,
} from '@dcupl/common';
import { DcuplList } from '@dcupl/core';
import { ApiService } from './services/api.service';
import { PdfService } from './services/pdf.service';
import { DcuplService } from './services/dcupl.service';
import { IconService } from './services/icon.service';
import { DlvAvailabilityItem } from './types/dlvAvailabilityItem.type';
import { DlvAvailabilityJsonItem } from './types/dlvAvailabilityJsonItem.type';
import { DlvDecorItem } from './types/dlvDecorItem.type';
import { DlvDecorJsonItem } from './types/dlvDecorJsonItem.type';
import { DlvFacetItem } from './types/dlvFacetItem.type';
import { DlvFacetObject } from './types/dlvFacetObject.type';
import { DlvProductDetailItem } from './types/dlvProductDetailItem.type';
import { DlvProductItem } from './types/dlvProductItem.type';
import { DlvPdfExportItem } from './types/dlvPdfExportItem.types';
import { DlvInstanceStatus } from './types/dlvInstanceStatus.type';
import { StoreService } from './services/store.service';
import { LabelService } from './services/label.service';
import { DlvFilterItem } from './types/dlvFilterItem.type';

@Component({
  selector: 'dlv-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  @Input() public filterValues: DlvFacetObject[] = [];
  public modal = false;
  public loading = true;
  public data: PivotResponse | undefined;
  public openedModal = false;
  public PDFExported = false;
  public queryBuilder = new DcuplQueryBuilder();
  public queryBuilderFacet = new DcuplQueryBuilder();
  public list!: DcuplList;
  public facetKeys = [
    'producttype',
    'decor_color',
    'producttype_feature',
    'decor_decorgroup',
    'decor_structuretype',
  ];
  public facets: DlvFacetItem[] = [];
  public allFacets: DlvFacetItem[] = [];
  public currentActiveFacets: DlvFacetObject[] = [];
  public status: DlvInstanceStatus | undefined;
  public availabilities: DlvAvailabilityItem[] = [];
  public pdfFilterItems: DlvFilterItem[] = [];
  public decors: DlvDecorItem[] = [];
  public products: DlvProductItem[] = [];
  public filterResults = 0;
  public activeFilters = 0;
  public isPDFLoading = false;
  public productDetailsXY: DlvProductDetailItem[] = [];
  public productDetailsZ: DlvProductDetailItem[] = [];
  public productDetailsGlues: DlvProductDetailItem[] = [];
  public productDetailsLayouts: DlvProductDetailItem[] = [];
  public productDetailsLayoutModels: DlvProductDetailItem[] = [];
  public error = false;

  public pivotOptions: PivotOptions = {
    rows: [
      { attribute: 'producttypeSV', calculateTotals: false },
      { attribute: 'generated_pivoting_value', calculateTotals: true },
    ],
    columns: [{ attribute: 'decor', calculateTotals: false }],
    values: [{ attribute: 'availabilityOrderForPivoting', types: ['max'] }],
    options: { calculateTotals: false },
  };

  constructor(
    private apiService: ApiService,
    private iconService: IconService,
    private cdRef: ChangeDetectorRef,
    private pdfService: PdfService,
    private storeService: StoreService,
    private labelService: LabelService
  ) {}

  ngOnInit(): void {
    if (window.location.href.includes('?error')) {
      this.error = true;
    }
    this.queryBuilder.init({ modelKey: 'productModel' });
    this.queryBuilderFacet.init({ modelKey: 'productModel' });
    this.setCountryAndLanguage();
    this.iconService.initRegistry();
    this.init();
  }

  private async init(): Promise<void> {
    await this.initViaApi('init');
    this.cdRef.detectChanges();
    this.pdfService.currentLoadingState.subscribe((loading) => {
      this.isPDFLoading = loading;
      this.cdRef.detectChanges();
    });
  }

  public setCountryAndLanguage(): void {
    const params = new URLSearchParams(window.location.search);
    let language = params.get('language');
    let country = params.get('country');

    if (language == null || country == null) {
      language = 'de';
      country = 'at';
    }

    this.storeService.setCountryAndLanguage(country, language);
  }

  private async getInitialData(): Promise<void> {
    if (!this.status) {
      this.status = await this.apiService.fetchInstanceStatus();

      if (!this.status || !this.status?.v) {
        this.error = true;
        alert('Something went wrong, please try again later.');
        return;
      }
      this.apiService.setInstanceVersion(this.status.v);
    }

    this.availabilities = await this.apiService.fetchAvailabilityData();
    this.decors = await this.apiService.fetchDecorData();
    this.products = await this.apiService.fetchProductData();
    this.productDetailsXY = await this.apiService.fetchProductDetailData(
      'productLayoutDimXYs'
    );
    this.productDetailsZ =
      await this.apiService.fetchProductDetailData('productLayoutDimZs');
    this.productDetailsGlues =
      await this.apiService.fetchProductDetailData('productLayoutGlues');
    this.productDetailsLayoutModels =
      await this.apiService.fetchProductDetailData('productLayoutModels');
    this.productDetailsLayouts =
      await this.apiService.fetchProductDetailData('productLayouts');

    const facetOptions = this.facetKeys.map((key) => {
      const options: DcuplFacetOptions = {
        attribute: key,
        calculateResults: false,
      };
      return options;
    });

    this.allFacets = await this.apiService.fetchFacetsData(
      facetOptions,
      this.queryBuilderFacet.getQuery()
    );

    for (let i = 0; i < this.allFacets[2].facets.length; i++) {
      if (this.allFacets[2].facets[i].key === 'TSG_1_2') {
        this.allFacets[2].facets[i].key = 'TSG_1_2x';
      }
    }
  }

  private async getPivotAndFacetDataBasedOnQuery(): Promise<void> {
    const data = await this.apiService.fetchPivotData(
      this.pivotOptions,
      this.queryBuilder.getQuery()
    );

    const facetOptions = this.facetKeys.map((key) => {
      const options: DcuplFacetOptions = {
        attribute: key,
        calculateResults: false,
      };
      return options;
    });

    this.facets = await this.apiService.fetchFacetsData(
      facetOptions,
      this.queryBuilder.getQuery()
    );

    for (let i = 0; i < this.facets[2].facets.length; i++) {
      if (this.facets[2].facets[i].key === 'TSG_1_2') {
        this.facets[2].facets[i].key = 'TSG_1_2x';
      }
    }

    if (data) {
      this.data = data;

      document.body.classList.remove('modal-open');

      if (this.data) {
        const paths = this.getPaths(this.data);
        Object.defineProperty(this.data, 'paths', {
          value: paths,
          enumerable: false,
          configurable: true,
        });
      }
    }
  }

  private async initViaApi(type = 'facet'): Promise<void> {
    this.loading = true;
    if (type === 'init') {
      await this.getInitialData();
      return;
    } else if (type === 'facet') {
      await this.getPivotAndFacetDataBasedOnQuery();
    }
    this.loading = false;
  }

  public openModal(): void {
    this.openedModal = true;
  }

  public triggerPDFExport(): void {
    this.PDFExported = true;
  }

  public abortPDF(): void {
    this.pdfService.abortPDF();
  }

  public closeModal(): void {
    this.openedModal = false;
  }

  public results(length: number): void {
    this.filterResults = length;
  }

  public setFilters(length: number): void {
    this.activeFilters = length;
  }

  private containsNumbers(str: string): boolean {
    return /\d/.test(str.toString());
  }

  public getPdfFacets(): void {
    const pdfFacets: any = this.currentActiveFacets;
    for (let i = 0; i < pdfFacets.length; i++) {
      pdfFacets[i].name = this.getFacetLabel(pdfFacets[i].key);
      if (pdfFacets[i].key === 'search') {
        const params = new URLSearchParams(window.location.search);
        const searchterm = params.get('search');
          pdfFacets[i] = {
          key: pdfFacets[i].key,
          name: searchterm
        }
      } else {
        for (let j = 0; j < pdfFacets[i].facets.length; j++) {
          const key = pdfFacets[i].facets[j];
          pdfFacets[i].facets[j] = {};
          pdfFacets[i].facets[j].key = key;
          if (this.getFacetParent(pdfFacets[i].facets[j].key)) {
            const parentKey = this.getFacetParent(pdfFacets[i].facets[j].key);
            let parentAdded = false;
              for (let k = 0; k < pdfFacets[i].facets.length; k++) {
                if (pdfFacets[i].facets[k].key === parentKey.key) {
                  parentAdded = true;
                  pdfFacets[i].facets[k].facets.push({
                    key: key,
                    name: this.getFacetLabel(key)
                  });
                }
              }
            if (!parentAdded) {
              pdfFacets[i].facets[j] = parentKey;
                  pdfFacets[i].facets[j].facets = [{
                  key: key,
                  name: this.getFacetLabel(key)
                }];
            }
          } else {
            pdfFacets[i].facets[j].name = this.getFacetLabel(pdfFacets[i].facets[j].key);
          }
        }
      }
   
    }
    this.pdfFilterItems = this.cleanupPDFFacets(pdfFacets);
  }

  public getFacetLabel(key: string): string | undefined {
    if (key === "availablility") {
      return this.labelService.getLabel('dlv.filter.in_stock');
    } else if (key === "decor_newDecor") {
      return this.labelService.getLabel('dlv.filter.new_decor');
    }
    for (let i = 0; i < this.allFacets.length; i++) {
      if (this.allFacets[i].key === key) {
          return this.labelService.getLabel('dlv.filter.' + key);
      }
      for (let j = 0; j < this.allFacets[i].facets.length; j++) {
        if (this.allFacets[i].facets[j].entry.key === key) {
          return this.allFacets[i].facets[j].entry['label'];
        }
      }
    }
    return;
  }

  public getFacetParent(key: string): any | undefined {
    for (let i = 0; i < this.allFacets.length; i++) {
      if (this.allFacets[i].key === key) {
          return;
      }
      for (let j = 0; j < this.allFacets[i].facets.length; j++) {
        if (this.allFacets[i].facets[j].entry.key === key && this.allFacets[i].facets[j].entry['parent']) {
          const parent = {
            key: this.allFacets[i].facets[j].entry['parent'].key,
            name: this.getFacetLabel(this.allFacets[i].facets[j].entry['parent'].key),
            facets: {}
          }
          return parent;
        }
      }
    }
    return;
  }

  public cleanupPDFFacets(pdfFacets: any[]): DlvFilterItem[] {
    for (let i = 0; i < pdfFacets.length; i++) {
        for (let j = 0; j < pdfFacets[i]?.facets?.length; j++) {
          if (!pdfFacets[i].facets[j].name) {
            pdfFacets[i].facets.splice(j,1);
            this.cleanupPDFFacets(pdfFacets);
          }
      }
    }
    return pdfFacets;
  }

  public async facetInit(activeFacets: DlvFacetObject[]): Promise<void> {
    this.queryBuilder = new DcuplQueryBuilder();

    this.currentActiveFacets = activeFacets;

    if (activeFacets.length === 0) {
      await this.initViaApi();
      this.cdRef.detectChanges();
      return;
    } else {
      const queryGroups: DcuplQueryGroup<DcuplQuery>[] = [];
      const queryDetails: DcuplQuery[][] = [];
      for (let i = 0; i < activeFacets.length; i++) {
        queryDetails[i] = [];
        for (let j = 0; j < activeFacets[i].facets.length; j++) {
          if (activeFacets[i].facets[j] == 'TSG_1_2x') {
            activeFacets[i].facets[j] = 'TSG_1_2';
          }
          if (activeFacets[i].key != 'search') {
            queryDetails[i].push({
              attribute: activeFacets[i].key,
              operator: 'eq',
              value: activeFacets[i].facets[j],
            });
          } else {
            if (this.containsNumbers(activeFacets[i].facets[j])) {
              queryGroups.push({
                groupKey: 'decornumber',
                groupType: 'or',
                queries: [
                  {
                    attribute: 'decornumber',
                    value: '/' + activeFacets[i].facets[j] + '/',
                    operator: 'find',
                    options: {
                      transform: ['lowercase'],
                    },
                  },
                ],
              });
            } else {
              queryGroups.push({
                groupKey: 'decorname',
                groupType: 'or',
                queries: [
                  {
                    attribute: 'decorname',
                    value: '/' + activeFacets[i].facets[j] + '/',
                    operator: 'find',
                    options: {
                      transform: ['lowercase'],
                    },
                  },
                ],
              });
            }
          }
        }
      }

      for (let i = 0; i < activeFacets.length; i++) {
        if (activeFacets[i].key != 'search') {
          queryGroups.push({
            groupKey: activeFacets[i].key,
            queries: queryDetails[i],
          });
        }
      }

      for (let i = 0; i < queryGroups.length; i++) {
        this.queryBuilder.applyQuery(queryGroups[i]);
      }

      await this.initViaApi();

      this.cdRef.detectChanges();
    }
  }

  /*
    creates a flat object map for the nested pivot values
    the pivot structure is recursive
  */
  public getPaths(
    pivotResponse: PivotResponse,
    paths: Record<string, unknown> = {},
    parentPath = 'root'
  ): Record<string, unknown> {
    if (pivotResponse?.values && pivotResponse?.values.length) {
      paths[parentPath] = pivotResponse.values[0];
    }

    if (pivotResponse?.rows) {
      for (const child of pivotResponse.rows) {
        this.getPaths(child, paths, `${parentPath}__${child.key}`);
      }
    }
    if (pivotResponse?.columns) {
      for (const child of pivotResponse.columns) {
        this.getPaths(child, paths, `${parentPath}__${child.key}`);
      }
    }

    return paths;
  }

  public exportPDF(exportData: DlvPdfExportItem): void {
    const url = new URL(window.location.href);
    const market =
      this.storeService.getConfig().country.toUpperCase() +
      '-' +
      this.storeService.getConfig().language.toUpperCase();
    let availablePDF = false;
    for (let i = 0; i < this.storeService.availablePDFMarkets().length; i++) {
      if (this.storeService.availablePDFMarkets()[i] == market) {
        availablePDF = true;
        break;
      }
    }

    if (
      this.activeFilters == 0 && !url.toString().includes('newDecors') &&
      !url.toString().includes('inStock') &&
      availablePDF
    ) {
      this.pdfService.getPDFfromServer(market);
      this.PDFExported = false;
    } else {
      const decors: DlvDecorJsonItem[] = [];
      const decorData = exportData.decors;
      const productsData = exportData.products;
      const availabilities: DlvAvailabilityJsonItem[] = [];
      const availabilityData = exportData.availabilities;

      for (let i = 0; i < decorData.length; i++) {
        decors.push({
          path: decorData[i].mainimagesmall,
          name: decorData[i].decorname,
          code: decorData[i].decornumber,
        });
      }

      for (let i = 0; i < availabilityData.length; i++) {
        if (availabilityData[i]) {
          availabilities.push({
            path: availabilityData[i].icon?.split('@')[0],
            name: availabilityData[i].label,
          });
        }
      }
      this.getPdfFacets();
      this.pdfService.generatePDF(decors, productsData, availabilities, this.pdfFilterItems);
      this.PDFExported = false;
    }
  }
}
export { DlvAvailabilityItem };
