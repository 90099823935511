import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'dlv-app-pivot-header',
  templateUrl: './pivot-header.component.html',
  styleUrls: ['./pivot-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PivotHeaderComponent implements OnInit {
  public icon = true;
  constructor() {}

  ngOnInit(): void {}
}
