import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private icons: string[] = [
    'circle-full',
    'circle-half',
    'circle-outline',
    'arrow-double',
    'arrow-long',
    'arrow',
    'cancel',
    'check',
    'decortype',
    'colors',
    'filters',
    'function',
    'search',
    'minus',
    'plus',
    'producttype',
    'structure',
    'cog',
    'dlv',
    'reset',
    'share',
    'length',
    'thickness',
    'download',
    'questionmark',
    'no-results',
    'error',
    'logo-egger',
    'fav-off',
    'fav-on',
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public initRegistry(): void {
    this.icons.map((iconName: string) => {
      this.matIconRegistry.addSvgIcon(
        iconName,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${iconName}.svg`
        )
      );
    });
  }
}
