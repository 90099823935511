import { Injectable } from '@angular/core';

export type DlvStoreConfig = {
  country: string;
  language: string;
};

@Injectable({
  providedIn: 'root',
})
export class StoreService {
  private config: DlvStoreConfig = {
    country: 'at',
    language: 'de',
  };

  public setCountryAndLanguage(country: string, language: string): void {
    this.config.country = country;
    this.config.language = language;
  }

  public getConfig(): DlvStoreConfig {
    return Object.assign({}, this.config);
  }

  public availablePDFMarkets(): string[] {
    return [
      'AT-DE',
      'DE-DE',
      'CH-DE',
      'CH-FR',
      'IT-IT',
      'IT-DE',
      'ES-ES',
      'FR-FR',
      'PL-PL',
      'NL-NL',
      'CZ-CS',
      'CZ-EN',
      'GB-EN',
      'RS-SR',
      'HR-HR',
      'HU-HU',
      'UA-UK',
      'SE-EN',
      'DK-EN',
      'NO-EN',
      'FI-EN',
      'IS-EN',
      'FO-EN',
    ];
  }

  public availableCountryTranslations(): string[] {
    return [
      'en-US',
      'es-CR',
      'es-DO',
      'es-EC',
      'es-GT',
      'es-MX',
      'es-PA',
      'es-PE',
      'es-SR',
      'es-SV',
      'es-VE',
      'fr-CA',
    ];
  }
}
