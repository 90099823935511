import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { DlvAvailabilityItem } from 'src/app/app.component';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'dlv-app-pivot-legend',
  templateUrl: './pivot-legend.component.html',
  styleUrls: ['./pivot-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PivotLegendComponent implements OnInit {
  @Input() public openedModal = false;
  @Input() public availabilities!: DlvAvailabilityItem[];
  @Output() modalClosed = new EventEmitter<boolean>();
  public legendItems: { color: string; icon: string }[] = [];
  constructor(public labelService: LabelService) {}

  ngOnInit(): void {
    this.legendItems = [
      { color: 'red', icon: 'circle-full' },
      { color: 'red', icon: 'circle-half' },
      { color: 'red', icon: 'circle-outline' },
      { color: 'black', icon: 'circle-outline' },
    ];
  }

  public closeLegendModal(): void {
    this.openedModal = false;
    document.body.classList.remove('modal-open');
    this.modalClosed.emit(true);
  }
}
