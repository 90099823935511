import { Injectable } from '@angular/core';
import {
  DcuplFacetOptions,
  DcuplGlobalQueryOptions,
  PivotOptions,
  PivotResponse,
} from '@dcupl/common';

import { DLV_ENVIRONMENT } from 'src/environments/environment';
import { DlvAvailabilityItem } from '../app.component';
import { DlvDecorItem } from '../types/dlvDecorItem.type';
import { DlvFacetItem } from '../types/dlvFacetItem.type';
import { DlvProductItem } from '../types/dlvProductItem.type';
import { DlvAppInstanceFacetOptions } from '../types/DlvAppInstanceFacetOptions.type';
import { DlvAppInstancePivotOptions } from '../types/dlvAppInstancePivotOptions.type';
import { DlvProductDetailItem } from '../types/dlvProductDetailItem.type';
import { DLV_INSTANCE_MAPPINGS } from '../mappings/instance-mappings';
import { DlvInstanceStatus } from '../types/dlvInstanceStatus.type';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = DLV_ENVIRONMENT.apiUrl;
  private apiKey = DLV_ENVIRONMENT.apiKey;

  private v = ''; // instance v parameter indicates the version of the instance config;

  constructor(private storeService: StoreService) {}

  private getInstanceKey(): string {
    const config = this.storeService.getConfig();
    return `${config.country.toLowerCase()}-${config.language.toLowerCase()}`;
  }

  private getBasePath(): string {
    const instanceKey = this.getInstanceKey();
    const runner = this.getRunnerForInstance(instanceKey);
    return `${this.baseUrl}/${runner}/instance/${instanceKey}`;
  }

  private getRunnerForInstance(instanceKey: string): string | undefined {
    for (const runner of DLV_INSTANCE_MAPPINGS) {
      for (const instance of runner.instances) {
        if (instance === instanceKey) {
          return runner.key;
        }
      }
    }
    return undefined;
  }

  public setInstanceVersion(v: string): void {
    this.v = v;
  }

  public async fetchInstanceStatus(): Promise<DlvInstanceStatus | undefined> {
    const basePath = this.getBasePath();
    const fullPath = `${basePath}/status`;
    const url = new URL(fullPath);

    url.searchParams.append('api-key', this.apiKey);

    try {
      const response = await fetch(url.toString());

      return (await response.json()) as DlvInstanceStatus;
    } catch (error) {
      return undefined;
    }
  }

  public async fetchPivotData(
    pivotOptions: PivotOptions,
    query: DcuplGlobalQueryOptions<unknown>
  ): Promise<PivotResponse | undefined> {
    const basePath = this.getBasePath();
    const fullPath = `${basePath}/pivot`;

    const options: DlvAppInstancePivotOptions = {
      modelKey: 'productModel',
      options: pivotOptions,
      query: query,
    };

    const url = new URL(fullPath);

    url.searchParams.append('api-key', this.apiKey);
    url.searchParams.append('data', JSON.stringify(options));
    url.searchParams.append('v', this.v);

    try {
      const response = await fetch(url.toString(), {
        method: 'GET',
      });
      const result = (await response.json()) as PivotResponse;
      return result;
    } catch (error) {
      return undefined;
    }
  }

  public async fetchFacetsData(
    facetOptions: DcuplFacetOptions[],
    query: DcuplGlobalQueryOptions<unknown>
  ): Promise<DlvFacetItem[]> {
    const basePath = this.getBasePath();
    const fullPath = `${basePath}/facets`;
    const url = new URL(fullPath);

    const options: DlvAppInstanceFacetOptions = {
      modelKey: 'productModel',
      options: facetOptions,
      query: query,
    };

    url.searchParams.append('api-key', this.apiKey);
    url.searchParams.append('data', JSON.stringify(options));
    url.searchParams.append('v', this.v);

    try {
      const response = await fetch(url.toString());
      return (await response.json()) as DlvFacetItem[];
    } catch (error) {
      return [];
    }
  }

  public async fetchAvailabilityData(): Promise<DlvAvailabilityItem[]> {
    const basePath = this.getBasePath();
    const fullPath = `${basePath}/query`;
    const url = new URL(fullPath);

    const options = {
      modelKey: 'availabilities',
      queries: [],
      projection: {
        $: false,
        label: true,
        icon: true,
      },
    };

    url.searchParams.append('api-key', this.apiKey);
    url.searchParams.append('data', JSON.stringify(options));
    url.searchParams.append('v', this.v);

    try {
      const response = await fetch(url.toString());
      return (await response.json()) as DlvAvailabilityItem[];
    } catch (error) {
      return [];
    }
  }

  public async fetchDecorData(): Promise<DlvDecorItem[]> {
    const basePath = this.getBasePath();
    const fullPath = `${basePath}/query`;
    const url = new URL(fullPath);

    const options = {
      modelKey: 'decorModel',
      queries: [],
      projection: {
        $: false,
        decornumber: true,
        decorname: true,
        mainimagesmall: true,
        newDecor: true,
      },
    };

    url.searchParams.append('api-key', this.apiKey);
    url.searchParams.append('data', JSON.stringify(options));
    url.searchParams.append('v', this.v);

    try {
      const response = await fetch(url.toString());
      return (await response.json()) as DlvDecorItem[];
    } catch (error) {
      return [];
    }
  }

  public async fetchProductData(): Promise<DlvProductItem[]> {
    const basePath = this.getBasePath();
    const fullPath = `${basePath}/query`;
    const url = new URL(fullPath);

    const options = {
      modelKey: 'producttypes',
      queries: [],
      projection: {
        $: false,
        key: true,
        label: true,
        icon: true,
        parent: {
          $: false,
          icon: true,
        },
      },
    };

    url.searchParams.append('api-key', this.apiKey);
    url.searchParams.append('data', JSON.stringify(options));
    url.searchParams.append('v', this.v);

    try {
      const response = await fetch(url.toString());
      return (await response.json()) as DlvProductItem[];
    } catch (error) {
      return [];
    }
  }

  public async fetchProductDetailData(
    type: string
  ): Promise<DlvProductDetailItem[]> {
    const basePath = this.getBasePath();
    const fullPath = `${basePath}/query`;
    const url = new URL(fullPath);

    const options = {
      modelKey: type,
      queries: [],
      projection: {
        $: false,
        key: true,
        label: true,
      },
    };

    url.searchParams.append('api-key', this.apiKey);
    url.searchParams.append('data', JSON.stringify(options));
    url.searchParams.append('v', this.v);

    try {
      const response = await fetch(url.toString());
      return (await response.json()) as DlvProductDetailItem[];
    } catch (error) {
      return [];
    }
  }
}
