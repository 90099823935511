<div class="hidden md:block w-10 h-10 z-100 bottom-10 right-10 absolute" (click)="scrollToFilter()">
  <div class="m-2 top-0 left-0" *ngIf="!inViewport" (scroll)="(inViewport)">
    <div id="floating-button" class="hidden">
      <div class="ml-8 p-0.5 bg-gray-700 rounded-full leading-4 w-5 fixed z-10">
        <p class="block text-white z-50 relative left-1 active-filter-text">{{ setFilters }}</p>
      </div>
      <button class="mt-2 ml-2 bg-red-600 shadow-button-rounded p-0.5 rounded-full leading-3 fixed">
        <mat-icon svgIcon="filters" class="inline w-5 h-5 m-2 text-white"></mat-icon>
      </button>
    </div>
  </div>
</div>

<div id="floating-button-mobile" class="md:hidden w-10 h-10 z-100 floating-button-top right-10 absolute bottom-0" (click)="openFilterMobile()">
  <div class="m-2 top-0 left-0">
    <div id="floating-button">
      <div class="ml-8 p-0.5 bg-gray-700 rounded-full leading-4 w-5 fixed z-10">
        <p class="block text-white z-50 relative left-1 active-filter-text">{{ setFilters }}</p>
      </div>
      <button class="mt-2 ml-2 bg-red-600 shadow-button-rounded p-0.5 rounded-full leading-3 fixed">
        <mat-icon svgIcon="filters" class="inline w-5 h-5 m-2 text-white"></mat-icon>
      </button>
    </div>
  </div>
</div>
