import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { AppComponent } from './app.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { LoadingSpinnerPDFComponent } from './components/loading-spinner-pdf/loading-spinner-pdf.component';
import { PivotFiltersComponent } from './components/pivot-filters/pivot-filters.component';
import { ErrorPageComponent } from './components/error-page/error-page.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MatIconModule } from '@angular/material/icon';
import { PivotLegendComponent } from './components/pivot-legend/pivot-legend.component';
import { PivotAdditionalLegendComponent } from './components/pivot-additional-legend/pivot-additional-legend.component';
import { PivotTableScrollableComponent } from './components/pivot-table-scrollable/pivot-table-scrollable.component';
import { PivotTableEmptyComponent } from './components/pivot-table-empty/pivot-table-empty.component';
import { FloatingButtonComponent } from './components/floating-button/floating-button.component';
import { PDFButtonComponent } from './components/pdf-button/pdf-button.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { PivotHeaderComponent } from './components/pivot-header/pivot-header.component';
import { ToastNoAnimationModule } from "ngx-toastr";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    PivotTableScrollableComponent,
    PivotTableEmptyComponent,
    PivotFiltersComponent,
    PivotLegendComponent,
    FloatingButtonComponent,
    PDFButtonComponent,
    LoadingSpinnerComponent,
    LoadingSpinnerPDFComponent,
    PivotAdditionalLegendComponent,
    ErrorPageComponent,
    PivotHeaderComponent,
  ],
  imports: [
    BrowserModule,
    MatIconModule,
    HttpClientModule,
    ScrollingModule,
    ToastNoAnimationModule.forRoot({
      toastClass: "dlv-toast",
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
