<div id="filter-desktop" class="bg-gray-100 inline-block p-6 w-full overflow-visible z-100 relative filter-desktop pb-0" *ngIf="facets.length > 0" (click)="toggleFilter('clear')">
  <div class="filter-desktop-container relative grid grid-cols-12 gap-6" (click)="toggleFilter('clear')">
    <form class="col-span-12 md:col-span-6 lg:col-span-4 flex items-center hover:cursor-pointer" (submit)="$event.preventDefault()">
      <div class="flex-1 relative">
        <div [ngClass]="searchResults() > 0 ? 'border-gray-400 hover:border-gray-700' : 'border-red-600 '" class="decor-search-input bg-white border rounded cursor-pointer h-14 flex flex-row flex-nowrap items-center">
          <mat-icon svgIcon="search" class="inline icon-large ml-3 relative float-left text-gray-iconLight"></mat-icon>
          <input #decorSearch class="w-full relative m-2" [ngClass]="hasSearchInput() ? 'text-gray-700' : 'text-gray-400'" [placeholder]="labelService.getLabel('dlv.filter.searchbar_text')" type="text" id="decor" name="decor" (keyup.enter)="searchDecor(decorSearch.value)" (input)="hasSearchInput()" />
          <div *ngIf="hasSearchInput()" class="relative pr-4 flex flex-nowrap items-end" (click)="searchDecor(decorSearch.value)">
            <mat-icon svgIcon="arrow-double" class="icon-cancel m-1 top-0.5 text-red-600 float-right"></mat-icon>
          </div>
          <div *ngIf="hasSearchInput()" (click)="deleteSearchInput()" class="relative pr-4 flex flex-nowrap items-end">
            <mat-icon svgIcon="cancel" class="m-1 top-0.5 text-gray-400 float-right icon-cancel"></mat-icon>
          </div>
        </div>
      </div>
    </form>

    <ng-container *ngFor="let item of allFacets; trackBy: trackByKey">
      <div (click)="$event.stopPropagation()" class="col-span-12 md:col-span-6 lg:col-span-4 flex items-center hover:cursor-pointer">
        <div class="flex-1 relative" [ngClass]="isFilterActive(item.key) ? '' : 'opacity-50 cursor-not-allowed'">
          <div (click)="isFilterActive(item.key) && toggleFilter(item.key, 'main')" [ngClass]="isFilterActive(item.key) ? 'cursor-pointer' : 'cursor-not-allowed'" class="bg-white border border-gray-400 hover:border-gray-700 rounded h-14 flex flex-row flex-nowrap items-center">
            <mat-icon svgIcon="{{ filterIcons[item.key] }}" class="inline m-2 ml-3 relative float-left text-gray-icon icon-large"></mat-icon>
            <p class="relative pr-4 flex-1 m-2">{{ labelService.getLabel('dlv.filter.' + item.key) }}</p>
            <div class="relative pr-4 flex flex-nowrap items-center">
              <p *ngIf="activeFilters(item.key, item) > 0" [ngClass]="[activeFilters(item.key, item) < 10 && activeFilters(item.key, item) !== 1 ? 'left-1.5' : '', activeFilters(item.key, item) === 1 ? 'left-1' : '', activeFilters(item.key, item) >= 10 ? 'left-2.5' : '']" class="block text-white z-50 relative active-filter-text">{{ activeFilters(item.key, item) }}</p>
              <p *ngIf="activeFilters(item.key, item) > 0" [ngClass]="activeFilters(item.key, item) < 10 ? 'w-5' : 'w-6'" class="block relative right-2 p-2 rounded-full bg-red-600 h-5"></p>
              <mat-icon svgIcon="arrow" class="w-4 h-4 m-1 relative text-red-600" [ngClass]="isActiveFilter(item.key) ? 'top-1 left-2' : 'transform rotate-180 -top-1'"></mat-icon>
            </div>
          </div>
          <div class="z-100 absolute w-full top-full bg-white shadow-lg" [ngClass]="isActiveFilter(item.key) ? '' : ' hidden'">
            <div class="desktop-filter-container max-h-56 overflow-y-scroll">
              <div *ngFor="let facet of item.facets; trackBy: trackByKey">
                <div (click)="isFilterActive(facet.key) && activateFacet(item, facet)" [ngClass]="isFilterActive(facet.key) ? '' : 'opacity-50'" class="hover:bg-red-600 text-gray-700 hover:text-white pt-2" *ngIf="item.key !== 'decor_decorgroup' && item.key !== 'decor_structuretype' && item.key !== 'producttype' && facet.entry['label']">
                  <ng-container *ngIf="facet.entry['mainimage']"><img class="headline-decor-icon w-6 h-6 mb-2 mx-3 inline" [src]="facet.entry['mainimage'].split('@')[0]" /></ng-container>
                  <ng-container *ngIf="facet.entry['icon']"><img class="headline-decor-icon w-6 h-6 mb-2 mx-3 inline" [src]="facet.entry['icon'].split('@')[0]" /></ng-container>
                  <ng-container *ngIf="!facet.entry['icon'] && !facet.entry['mainimage']"><div class="w-6 h-6 mb-2 mx-3 inline-block"></div></ng-container>
                  <p *ngIf="!facet.entry['icon'] && !facet.entry['mainimage']" class="inline text-sm -top-3 relative">{{ facet.entry['label'] }}</p>
                  <p *ngIf="facet.entry['icon'] || facet.entry['mainimage']" class="inline text-sm -top-1 relative">{{ facet.entry['label'] }}</p>
                  <span [className]="isActiveFacet(facet.key) ? '' : ' hidden'">
                    <mat-icon svgIcon="check" [ngClass]="!facet.entry['icon'] && !facet.entry['mainimage'] ? 'relative top-1' : ''" class="w-4 h-4 m-1 mt-0 mr-2 float-right"></mat-icon>
                  </span>
                </div>
                <div id="{{ facet.key }}" class="flex flex-row flex-nowrap items-center px-4 py-2 truncate border-b border-gray-200" [ngClass]="isFilterActive(facet.key) ? '' : 'opacity-50'" *ngIf="!facet.entry['parent'] && (item.key === 'decor_decorgroup' || item.key === 'decor_structuretype' || item.key === 'producttype')">
                  <label (click)="isFilterActive(facet.key) && activateFacet(item, facet, 'parent')" [ngClass]="[isActiveFacet(facet.key) ? 'checked' : '', hasActiveChild(facet.key) ? 'checked-half' : '']" class="dropdown-collapse-checkbox inline-flex items-start cursor-pointer mr-2.5">
                    <i class="icon icon-checkbox text-red-600"></i>
                  </label>
                  <p class="text-gray-700 text-sm w-full ml-2" (click)="isFilterActive(facet.key) && toggleFilter(facet.key)">{{ facet.entry['label'] }}</p>
                  <mat-icon (click)="isFilterActive(facet.key) && toggleFilter(facet.key)" *ngIf="!isActiveFilter(facet.key) && hasChildren(facet.key)" svgIcon="plus" class="w-4 h-4 m-1 mt-2 mr-2 transform rotate-180 inline-flex items-end text-red-600"></mat-icon>
                  <mat-icon (click)="isFilterActive(facet.key) && toggleFilter(facet.key)" *ngIf="isActiveFilter(facet.key) && hasChildren(facet.key)" svgIcon="minus" class="w-4 h-4 m-1 mt-2 mr-2 transform rotate-180 inline-flex items-end"></mat-icon>
                  <div *ngIf="!hasChildren(facet.key)" class="w-6 h-6 m-1 mt-2 mr-2 inline-flex items-end"></div>
                </div>

                <ng-container *ngIf="item.key === 'decor_decorgroup' || item.key === 'decor_structuretype' || item.key === 'producttype'">
                  <ng-container *ngFor="let facet2 of item.facets">
                    <ng-container *ngIf="facet2.entry['parent'] && facet2.entry['parent']['key'] === facet.key">
                      <div (click)="isFilterActive(facet2.key) && activateFacet(item, facet2, 'child')" id="{{ facet2.key }}" [ngClass]="[isActiveFilter(facet2.entry['parent']['key']) ? 'hover:bg-red-600 hover:text-white text-gray-700 ml-12 p-2 flex flex-row flex-nowrap items-center' : ' hidden hover:bg-gray-100 ml-12 p-2', isFilterActive(facet2.key) ? '' : 'opacity-50']">
                        <label [className]="(isActiveFacet(facet2.key, 'child') && isFilterActive(facet2.key)) || (isActiveFacet(facet.key) && isFilterActive(facet2.key)) ? 'checked' : ''" class="dropdown-collapse-checkbox inline-flex items-start cursor-pointer mr-2.5">
                          <i class="icon icon-checkbox text-red-600"></i>
                        </label>
                        <p class="text-sm w-full ml-2">{{ facet2.entry['label'] }}</p>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </div>
            <div class="bg-gray-100 sticky bottom-0 min-h-17 cursor-default">
              <button *ngIf="activeFilters(item.key, item) > 0" class="inline-block button-table text-red-600 p-2 text-sm bottom-0 right-0 m-4" (click)="resetFilter(item.key)">
                <span class="button-text -top-px text-sm mx-1 relative">{{ activeFilters(item.key, item) }} {{ labelService.getLabel('dlv.filter.reset_filter') }}</span>
              </button>
              <button class="inline-block button-table text-white px-2 py-3 text-sm bg-red-600 absolute bottom-0 right-0 m-4" (click)="applyFilter()">
                <span class="button-text -top-px text-xs mx-1 relative uppercase font-bold">{{ labelService.getLabel('dlv.filter.apply_filter') }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="h-full w-full inline-block relative ml-1" (click)="$event.stopPropagation()">
    <input type="text" name="ToggleFilter" class="w-full h-full cursor-pointer border-0 outline-0 text-lg text-gray-700 pt-3 pb-3 leading-snug valid:pt-5 valid:pb-2 hidden" />
    <label class="float-left mt-4 text-gray-700 text-lg flex h-full py-2 items-center leading-snug">
      <span (click)="toggleStockFilter()" [className]="activeStockFilter ? 'switch-button--active switch-button cursor-pointer mr-5 text-red-600' : 'switch-button cursor-pointer mr-5 text-red-600'">
        <span [className]="activeStockFilter ? 'switch-slider switch-slider--active' : 'switch-slider'"></span>
      </span>
      <span class="text-sm">{{ labelService.getLabel('dlv.filter.in_stock') }}</span>
    </label>

    <input type="text" name="ToggleFilter" class="w-full h-full cursor-pointer border-0 outline-0 text-lg text-gray-700 pt-3 pb-3 leading-snug valid:pt-5 valid:pb-2 hidden" />
    <label class="float-left mt-4 ml-10 text-gray-700 text-lg flex h-full py-2 items-center leading-snug">
      <span (click)="toggleNewDecorFilter()" [className]="activeNewDecorFilter ? 'switch-button--active switch-button cursor-pointer mr-5 text-red-600' : 'switch-button cursor-pointer mr-5 text-red-600'">
        <span [className]="activeNewDecorFilter ? 'switch-slider switch-slider--active' : 'switch-slider'"></span>
      </span>
      <span class="text-sm">{{ labelService.getLabel('dlv.filter.new_decor') }}</span>
    </label>

    <input type="text" name="ToggleFilter" class="w-full h-full cursor-pointer border-0 outline-0 text-lg text-gray-700 pt-3 pb-3 leading-snug valid:pt-5 valid:pb-2 hidden" />
    <label class="float-left mt-4 ml-10 text-gray-700 text-lg flex h-full py-2 items-center leading-snug">
      <span (click)="toggleFavoriteFilter()" [className]="activeFavoriteFilter ? 'switch-button--active switch-button cursor-pointer mr-5 text-red-600' : 'switch-button cursor-pointer mr-5 text-red-600'">
        <span [className]="activeFavoriteFilter ? 'switch-slider switch-slider--active' : 'switch-slider'"></span>
      </span>
      <span class="text-sm">{{ labelService.getLabel('dlv.filter.favorites') }}</span>
    </label>

    <button class="button-table text-red-600 p-2 mr-0 pr-0 float-right bottom-0 right-0 m-4" [ngClass]="numberOfFiltersFirstLevel > 0 ? '' : ' hidden'" (click)="deleteAllFilters()">
      <span class="button-text -top-px mx-1 relative">{{ labelService.getLabel('dlv.filter.reset_all_filter') }}</span>
    </button>
  </div>
</div>

<div id="filter-mobile" *ngIf="facets.length > 0" class="inline-block w-full overflow-hidden z-100 relative filter-mobile bg-white">
  <h2 class="text-lg text-center m-4">Filtern</h2>
  <div class="relative grid grid-cols-12">
    <form class="col-span-12 md:col-span-6 lg:col-span-4 flex mb-4 mx-2 items-center hover:cursor-pointer" (submit)="$event.preventDefault()">
      <div class="flex-1 relative">
        <div [ngClass]="searchResults() > 0 ? 'border-gray-400 hover:border-gray-700' : 'border-red-600 '" class="decor-search-input bg-white border rounded cursor-pointer h-14 flex flex-row flex-nowrap items-center">
          <mat-icon svgIcon="search" class="inline w-5 h-6 m-2 min-w-5 max-w-5 ml-3 relative float-left text-gray-iconLight"></mat-icon>
          <input #decorSearchMobile class="w-full relative m-2" [ngClass]="hasSearchInputMobile() ? 'text-gray-700' : 'text-gray-400'" [placeholder]="labelService.getLabel('dlv.filter.searchbar_text')" type="text" name="decor" (keyup.enter)="searchDecor(decorSearchMobile.value, 'mobile')" (input)="hasSearchInputMobile()" />
          <div *ngIf="hasSearchInputMobile()" class="relative pr-4 flex flex-nowrap items-end" (click)="searchDecor(decorSearchMobile.value, 'mobile')">
            <mat-icon svgIcon="arrow-double" class="icon-cancel m-1 top-0.5 text-red-600 float-right"></mat-icon>
          </div>
          <div *ngIf="hasSearchInputMobile()" (click)="deleteSearchInput('mobile')" class="relative pr-4 flex flex-nowrap items-end">
            <mat-icon svgIcon="cancel" class="m-1 top-0.5 text-gray-400 float-right icon-cancel"></mat-icon>
          </div>
        </div>
      </div>
    </form>

    <ng-container *ngFor="let item of allFacets; trackBy: trackByKey">
      <div *ngIf="item.key !== 'decor'" class="col-span-12 md:col-span-6 lg:col-span-4 flex items-center hover:cursor-pointer">
        <div class="flex-1 relative" [ngClass]="isFilterActive(item.key) ? '' : 'opacity-50 cursor-not-allowed'">
          <div (click)="isFilterActive(item.key) && toggleFilter(item.key, 'main')" class="bg-white border-b border-b-gray-200 cursor-pointer h-14 flex flex-row flex-nowrap items-center">
            <mat-icon svgIcon="{{ filterIcons[item.key] }}" class="inline w-6 h-6 m-2 ml-3 relative float-left text-gray-icon"></mat-icon>
            <p class="relative pr-4 flex-1 m-2">{{ labelService.getLabel('dlv.filter.' + item.key) }}</p>
            <div class="relative pr-4 flex flex-nowrap items-center">
              <p [ngClass]="[activeFilters(item.key, item) < 10 && activeFilters(item.key, item) !== 1 ? 'left-1.5' : '', activeFilters(item.key, item) === 1 ? 'left-1' : '', activeFilters(item.key, item) >= 10 ? 'left-2.5' : '']" class="block text-white z-50 relative active-filter-text" *ngIf="activeFilters(item.key, item) > 0">{{ activeFilters(item.key, item) }}</p>
              <p [ngClass]="activeFilters(item.key, item) < 10 ? 'w-5' : 'w-6'" class="block relative right-2 p-2 rounded-full bg-red-600 h-5" *ngIf="activeFilters(item.key, item) > 0"></p>
              <mat-icon svgIcon="arrow" class="w-4 h-4 m-1 relative text-red-600" [ngClass]="isActiveFilter(item.key) ? 'top-1 left-2' : 'transform rotate-90 md:-top-1 top-1'"></mat-icon>
            </div>
          </div>
          <div class="z-100 top-0 fixed w-full bg-white mobile-full-height" [ngClass]="isActiveFilter(item.key) ? '' : ' hidden'">
            <mat-icon (click)="closeFilter(item.key)" svgIcon="arrow" class="z-100 w-4 h-4 ml-4 my-4 transform rotate-270 text-red-600 float-left absolute -top-0.5"></mat-icon>
            <h2 class="z-50 text-lg text-center relative mt-4 pb-4 border-b border-b-gray-400">{{ labelService.getLabel('dlv.filter.' + item.key) }}</h2>
            <div class="min-h-full max-h-full">
              <div class="overflow-y-scroll mobile-filter-container">
                <div *ngFor="let facet of item.facets; trackBy: trackByKey">
                  <div (click)="isFilterActive(facet.key) && activateFacet(item, facet)" [ngClass]="isFilterActive(facet.key) ? '' : 'opacity-50'" class="hover:bg-red-600 text-gray-700 hover:text-white pt-3 pb-1" *ngIf="item.key !== 'decor_decorgroup' && item.key !== 'decor_structuretype' && item.key !== 'producttype' && facet.entry['label']">
                    <ng-container *ngIf="facet.entry['mainimage']"><img class="headline-decor-icon w-6 h-6 mb-2 mx-3 inline" [src]="facet.entry['mainimage'].split('@')[0]" /></ng-container>
                    <ng-container *ngIf="facet.entry['icon']"><img class="headline-decor-icon w-6 h-6 mb-2 mx-3 inline" [src]="facet.entry['icon'].split('@')[0]" /></ng-container>
                    <ng-container *ngIf="!facet.entry['icon'] && !facet.entry['mainimage']"><div class="w-6 h-6 mb-2 mx-3 inline-block"></div></ng-container>
                    <p *ngIf="!facet.entry['icon'] && !facet.entry['mainimage']" class="inline text-sm -top-3 relative">{{ facet.entry['label'] }}</p>
                    <p *ngIf="facet.entry['icon'] || facet.entry['mainimage']" class="inline text-sm -top-1 relative">{{ facet.entry['label'] }}</p>
                    <span [className]="isActiveFacet(facet.key) ? '' : ' hidden'">
                      <mat-icon svgIcon="check" class="w-4 h-4 m-1 mt-0 mr-2 float-right"></mat-icon>
                    </span>
                  </div>
                  <div class="flex flex-row flex-nowrap items-center px-4 py-3 truncate border-b border-gray-200" [ngClass]="isFilterActive(facet.key) ? '' : 'opacity-50'" *ngIf="!facet.entry['parent'] && (item.key === 'decor_decorgroup' || item.key === 'decor_structuretype' || item.key === 'producttype')">
                    <label (click)="activateFacet(item, facet, 'parent')" [ngClass]="[isActiveFacet(facet.key) ? 'checked' : '', hasActiveChild(facet.key) ? 'checked-half' : '']" class="dropdown-collapse-checkbox inline-flex items-start cursor-pointer mr-2.5">
                      <i class="icon icon-checkbox text-red-600"></i>
                    </label>
                    <p class="text-gray-700 text-sm w-full" (click)="isFilterActive(facet.key) && toggleFilter(facet.key)">{{ facet.entry['label'] }}</p>
                    <mat-icon (click)="isFilterActive(facet.key) && toggleFilter(facet.key)" *ngIf="!isActiveFilter(facet.key) && hasChildren(facet.key)" svgIcon="plus" class="w-4 h-4 m-1 mt-2 mr-2 transform rotate-180 inline-flex items-end text-red-600"></mat-icon>
                    <mat-icon (click)="isFilterActive(facet.key) && toggleFilter(facet.key)" *ngIf="isActiveFilter(facet.key) && hasChildren(facet.key)" svgIcon="minus" class="w-4 h-4 m-1 mt-2 mr-2 transform rotate-180 inline-flex items-end"></mat-icon>
                    <div *ngIf="!hasChildren(facet.key)" class="w-6 h-6 m-1 mt-2 mr-2 inline-flex items-end"></div>
                  </div>

                  <ng-container *ngIf="item.key === 'decor_decorgroup' || item.key === 'decor_structuretype' || item.key === 'producttype'">
                    <ng-container *ngFor="let facet2 of item.facets">
                      <ng-container *ngIf="facet2.entry['parent'] && facet2.entry['parent']['key'] === facet.key">
                        <div (click)="isFilterActive(facet2.key) && activateFacet(item, facet2, 'child')" id="{{ facet2.key }}--mobile" [ngClass]="[isActiveFilter(facet2.entry['parent']['key']) ? 'hover:bg-gray-100 ml-12 p-2 py-3 flex flex-row flex-nowrap items-center' : ' hidden hover:bg-gray-100 ml-12 p-2', isFilterActive(facet2.key) ? '' : 'opacity-50']">
                          <label [className]="(isActiveFacet(facet2.key, 'child') && isFilterActive(facet2.key)) || (isActiveFacet(facet.key) && isFilterActive(facet2.key)) ? 'checked' : ''" class="dropdown-collapse-checkbox inline-flex items-start cursor-pointer mr-2.5">
                            <i class="icon icon-checkbox text-red-600"></i>
                          </label>
                          <p class="text-gray-700 text-sm w-full ml-2">{{ facet2.entry['label'] }}</p>
                        </div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </div>
              </div>

              <div class="bg-gray-100 absolute bottom-0 w-full">
                <button class="inline-block button-table text-red-600 p-2 text-sm bottom-0 right-0 m-4" (click)="deleteAllFilters(false, false, 'mobile')">
                  <span class="button-text -top-px text-sm mx-1 relative">{{ labelService.getLabel('dlv.filter.reset_all_filter') }}</span>
                </button>
                <button class="inline-block button-table text-white px-2 py-3 text-sm bg-red-600 absolute bottom-0 right-0 m-4" (click)="applyFilter('mobile')">
                  <span class="button-text -top-px text-xs mx-1 relative uppercase font-bold">{{ labelService.getLabel('dlv.filter.apply_filter') }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="relative ml-2">
    <input type="text" name="ToggleFilter" class="w-full h-full cursor-pointer border-0 outline-0 text-lg text-gray-700 pt-3 pb-3 leading-snug valid:pt-5 valid:pb-2 hidden" />
    <label class="mt-4 text-gray-700 text-lg flex h-full py-2 items-center leading-snug">
      <span (click)="toggleStockFilter('mobile')" [className]="activeStockFilter ? 'switch-button--active switch-button cursor-pointer mr-5 text-red-600' : 'switch-button cursor-pointer mr-5 text-red-600'">
        <span [className]="activeStockFilter ? 'switch-slider switch-slider--active' : 'switch-slider'"></span>
      </span>
      <span class="text-sm">{{ labelService.getLabel('dlv.filter.in_stock') }}</span>
    </label>

    <input type="text" name="ToggleFilter" class="w-full h-full cursor-pointer border-0 outline-0 text-lg text-gray-700 pt-3 pb-3 leading-snug valid:pt-5 valid:pb-2 hidden" />
    <label class="mt-4 text-gray-700 text-lg flex h-full py-2 items-center leading-snug">
      <span (click)="toggleNewDecorFilter('mobile')" [className]="activeNewDecorFilter ? 'switch-button--active switch-button cursor-pointer mr-5 text-red-600' : 'switch-button cursor-pointer mr-5 text-red-600'">
        <span [className]="activeNewDecorFilter ? 'switch-slider switch-slider--active' : 'switch-slider'"></span>
      </span>
      <span class="text-sm">{{ labelService.getLabel('dlv.filter.new_decor') }}</span>
    </label>

    <input type="text" name="ToggleFilter" class="w-full h-full cursor-pointer border-0 outline-0 text-lg text-gray-700 pt-3 pb-3 leading-snug valid:pt-5 valid:pb-2 hidden" />
    <label class="mt-4 text-gray-700 text-lg flex h-full py-2 items-center leading-snug">
      <span (click)="toggleFavoriteFilter('mobile')" [className]="activeFavoriteFilter ? 'switch-button--active switch-button cursor-pointer mr-5 text-red-600' : 'switch-button cursor-pointer mr-5 text-red-600'">
        <span [className]="activeFavoriteFilter ? 'switch-slider switch-slider--active' : 'switch-slider'"></span>
      </span>
      <span class="text-sm">{{ labelService.getLabel('dlv.filter.favorites') }}</span>
    </label>
  </div>
  <div class="bg-gray-100 absolute bottom-0 w-full">
    <button class="inline-block button-table text-red-600 p-2 text-sm bottom-0 right-0 m-4" (click)="deleteAllFilters(false, false, 'mobile')">
      <span class="button-text -top-px text-sm mx-1 relative">{{ labelService.getLabel('dlv.filter.reset_filter') }}</span>
    </button>
    <button class="inline-block button-table text-white px-2 py-3 text-sm bg-red-600 absolute bottom-0 right-0 m-4" (click)="applyFilter('mobile', decorSearchMobile.value)">
      <span class="button-text -top-px text-xs mx-1 relative uppercase font-bold">{{ labelService.getLabel('dlv.filter.apply_filter') }}</span>
    </button>
  </div>
</div>

<div id="filter-results" class="lg:text-lg text-base px-4 md:px-6 mb-3 relative md:top-10 top-4 md:mb-0" *ngIf="filterResults > 0">
  <span class="text-red-600">{{ filterResults }} </span>
  <span *ngIf="filterResults === 1">{{ labelService.getLabel('dlv.result') }}</span>
  <span *ngIf="filterResults > 1">{{ labelService.getLabel('dlv.results') }}</span>
</div>

<div class="text-lg px-12 mb-10 absolute top-52 md:top-128 lg:top-96 mt-10 w-full text-center centered" *ngIf="filterResults === 0">
  <mat-icon svgIcon="no-results" class="no-result-icon"></mat-icon>
  <p class="mb-2 mt-3 uppercase text-black text-lg">{{ labelService.getLabel('dlv.no_results_headline') }}</p>
  <p class="text-sm">{{ labelService.getLabel('dlv.no_results_description') }}</p>
</div>
