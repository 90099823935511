import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'dlv-app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingButtonComponent implements OnInit, OnChanges {
  @Input() public setFilters!: number;
  @Input() public filterResults!: number;

  public inViewport = false;
  public currentUrl = window.location.href;
  constructor() {}

  ngOnInit(): void {
    addEventListener('popstate', () => {
      this.checkOpenFilter();
    });
    addEventListener('scroll', () => {
      if (
        !this.isInViewport() &&
        document.getElementById('floating-button')?.classList.contains('hidden')
      ) {
        document.getElementById('floating-button')?.classList.remove('hidden');
      } else if (
        this.isInViewport() &&
        !document
          .getElementById('floating-button')
          ?.classList.contains('hidden')
      ) {
        document.getElementById('floating-button')?.classList.add('hidden');
      }
    });

    setTimeout(() => {
      document.getElementById('filter-mobile')?.classList.add('filter-hidden');
    }, 100);
  }

  ngOnChanges(): void {}

  public scrollToFilter(): void {
    const filter = document.getElementById('filter-desktop');
    if (filter) {
      window.scrollTo(filter.offsetLeft, filter.offsetTop);
    }
  }

  public isInViewport(): boolean {
    const element = document.getElementById('filter-desktop');
    if (!element) return false;
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= -element.offsetHeight &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  public openFilterMobile(): void {
    document.getElementById('filter-mobile')?.classList.remove('filter-hidden');
    document.getElementById('egger-dlv-matrix')?.classList.add('hidden');
    document.getElementById('dlv-header')?.classList.add('hidden');
    document.getElementById('filter-results')?.classList.add('hidden');
    document.getElementById('floating-button-mobile')?.classList.add('hidden');

    const url = new URL(window.location.href);
    if (!url.toString().includes('openFilterMobile=true')) {
      url.searchParams.append('openFilterMobile', 'true');
      document.getElementById('additional-legend')?.classList.add('hidden');
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }
    this.currentUrl = url.toString();
  }

  public checkOpenFilter(): void {
    if (this.currentUrl.toString().includes('openFilterMobile=true')) {
      document.getElementById('filter-mobile')?.classList.add('filter-hidden');
      if (this.filterResults !== 0) {
        document.getElementById('egger-dlv-matrix')?.classList.remove('hidden');
      }
      document.getElementById('filter-results')?.classList.remove('hidden');
      document.getElementById('additional-legend')?.classList.remove('hidden');
      document.getElementById('dlv-header')?.classList.remove('hidden');
      document
        .getElementById('floating-button-mobile')
        ?.classList.remove('hidden');
    }
  }
}
