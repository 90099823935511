<div id="matrix-top"></div>
<div id="egger-dlv-matrix" class="h-mobile-full-height dlv-scrollable-table matrix-notouch" [ngClass]="[results > 0 ? '' : 'hidden']">
  <div class="flex w-full" [class]="[results > 0 ? '' : 'hidden']">
    <div class="w-0 h-0 toggle-decor-button relative">
      <button (click)="toggleMinimizeDecor()" class="decor-button-element button-table sticky z-50 block h-8 w-8 bg-white cursor-pointer rounded-full shadow-button-rounded">
        <mat-icon svgIcon="arrow" class="inline w-4 h-4 transform" [class]="openDecor ? 'm-2' : 'rotate-180 -ml-2'"></mat-icon>
      </button>
    </div>
    <div class="w-0 h-0 toggle-product-button relative">
      <button (click)="toggleMinimizeHeadline()" class="product-button-element button-table sticky z-50 block h-8 w-8 bg-white cursor-pointer rounded-full shadow-button-rounded">
        <mat-icon svgIcon="arrow" class="inline w-4 h-4 transform" [class]="openProduct ? 'ml-2 rotate-270' : 'mt-2 mr-2 rotate-90'"></mat-icon>
      </button>
    </div>
    <!-- heading rows -->
    <div class="flex flex-col border-r border-black shadow-xl" [ngClass]="openProduct ? 'lg:w-120 w-64' : 'w-28'">
      <div class="h-px"></div>
      <div class="relative z-20 bg-gray-50 border-b border-r border-gray-400 transition-transform duration-50 ease-in-out sticky top-0 empty-cell" [ngClass]="[!openDecor ? 'h-40' : 'h-104', openProduct ? 'lg:w-120 w-64' : 'w-28']">
        <!-- settings -->
        <div class="inline m-2 absolute top-0 left-0 lg:top-1.5 lg:left-1.5">
          <button class="mt-2 ml-1 button-table tooltip p-0.5 rounded-full text-sm leading-3" (click)="openModal()">
            <span class="button-text">
              <mat-icon svgIcon="questionmark" class="inline w-5 h-5 m-1 ml-0.5 text-red-600"></mat-icon>
            </span>
            <span class="tooltiptext" style="visibility: hidden">
              <ul class="-top-2 relative">
                <li class="float-left" (click)="exportPDF()">
                  <mat-icon svgIcon="dlv" class="inline m-1 relative top-3"></mat-icon>
                  <span class="text-xs text-black">{{ labelService.getLabel('dlv.pdf_export') }}</span>
                </li>
                <li class="float-left" (click)="openModal()">
                  <mat-icon svgIcon="share" class="inline m-1 relative top-3"></mat-icon>
                  <span class="text-xs text-black">{{ labelService.getLabel('dlv.open_legend') }}</span>
                </li>
                <li class="float-left" (click)="onBackToStartClicked()">
                  <mat-icon svgIcon="reset" class="inline m-1 relative top-3"></mat-icon>
                  <span class="text-xs text-black">{{ labelService.getLabel('dlv.to_top') }}</span>
                </li>
              </ul>
            </span>
          </button>
          <span class="inline text-sm text-black relative -top-3 cursor-pointer" (click)="openModal()" [ngClass]="openProduct ? '' : 'hidden'">{{ labelService.getLabel('dlv.legend') }}</span>
        </div>

        <button type="button" class="absolute bottom-0 right-0 m-4 text-red-600 p-2 text-xs bg-red-100 rounded-full inline-flex justify-center items-center focus-visible:outline-none" (click)="onBackToStartClicked()" [ngClass]="openProduct ? '' : 'hidden'">
          <div class="w-3 h-3 mr-2 transform">
            <mat-icon class="mat-icon-custom-size" svgIcon="arrow-long"></mat-icon>
          </div>
          <span>{{ labelService.getLabel('dlv.to_top') }}</span>
        </button>
      </div>
      <ng-container *ngFor="let row of productRendered; trackBy: trackByKey">
        <div
          (click)="toggleRow(row)"
          class="h-16 pl-8 pr-3 justify-end items-center inline-flex border-b-gray-100 border-b-4 cursor-pointer matrix-product-row"
          [ngClass]="{
            'relative after:w-1 after:h-16 after:left-0 after:absolute after:top-0 after:bg-gray-400': openRows.has(row.key)
          }"
        >
          <img *ngIf="products[getIndexForData(row.key, 'product')].parent && products[getIndexForData(row.key, 'product')].parent.icon" class="headline-product-icon w-6 h-6 inline -mt-1.5" [ngClass]="openProduct ? 'ml-5 mr-3' : 'mr-2'" [src]="products[getIndexForData(row.key, 'product')].parent.icon.split('@')[0]" />
          <span *ngIf="products[getIndexForData(row.key, 'product')].parent && !products[getIndexForData(row.key, 'product')].parent.icon" class="inline-block w-6 h-6 ml-5 mr-3 -mt-1.5"></span>
          <div *ngIf="products[getIndexForData(row.key, 'product')]" [class]="openProduct ? 'headline-product-text relative inline' : 'hidden-text relative inline'" attr.data-row-id="{{ row.key }}">
            {{ products[getIndexForData(row.key, 'product')].label }}
          </div>
          <mat-icon class="inline w-4 h-4 m-1 min-w-6 float-right" [ngClass]="openRows.has(row.key) ? 'relative left-2' : 'transform rotate-180'" svgIcon="arrow"></mat-icon>
        </div>
        <ng-container *ngIf="openRows.has(row.key)">
          <div *ngFor="let childRow of row.children; trackBy: trackByKey" [ngClass]="openProduct ? '' : 'closed-detail-headline'" class="dlv-matrix-subcell h-16 pr-1 pl-4 lg:px-8 justify-start items-center inline-flex border-b-gray-50 border-b-4 bg-gray-100 relative after:w-1 after:h-16 after:left-0 after:absolute after:top-0 after:bg-gray-400">
            <mat-icon attr.data-row-id="{{ row.key }}-{{ childRow.key.split(',')[1] }}" class="detail-icon" svgIcon="thickness" [ngClass]="openProduct ? '' : 'hidden-icon'"></mat-icon>
            <span *ngIf="productDetailsXY[getIndexForData(childRow.key.split('__')[1], 'XY')]" class="pl-1 pr-1 lg:pr-3 text-ellipsis overflow-hidden text-sm" [ngClass]="openProduct ? '' : 'detail-text-small'"> <mat-icon class="detail-icon" svgIcon="thickness" [ngClass]="openProduct ? 'hidden-icon' : 'mr-1'"></mat-icon>{{ productDetailsXY[getIndexForData(childRow.key.split('__')[1], 'XY')].label }}</span>
            <mat-icon [ngClass]="openProduct ? '' : 'hidden-icon'" class="detail-icon" svgIcon="length"></mat-icon>
            <span *ngIf="productDetailsZ[getIndexForData(childRow.key.split('__')[2], 'Z')]" [ngClass]="openProduct ? '' : 'hidden'" class="pl-1 pr-3 text-sm">{{ productDetailsZ[getIndexForData(childRow.key.split('__')[2], 'Z')].label }}</span>
            <span [ngClass]="openProduct ? '' : 'hidden'" class="lg:ml-4 ml-0.75 text-sm inline-block max-w-20 overflow-hidden whitespace-nowrap text-ellipsis"> {{ childRow.key.split('__')[3] }} {{ childRow.key.split('__')[4] }}</span>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngFor="let row of additionalRows">
        <div class="h-16 pl-8 pr-3 justify-end items-center inline-flex border-b-gray-100 border-b-4 cursor-pointer">
          <span class="inline-block w-6 h-6 ml-5 mr-3 -mt-1.5"></span>
        </div>
      </ng-container>
    </div>
    <!-- columns -->
    <div class="flex-1 shrink-0">
      <div id="viewSquare" class="h-0"></div>
      <div class="table-content cdk-virtual-scroll-data-source-example">
        <div class="shadow"></div>
        <div id="matrix-scrollbar" class="scrollbar-container w-8 bg-red-600 bottom-0 fixed z-110"></div>
        <cdk-virtual-scroll-viewport
          orientation="horizontal"
          [itemSize]="colWidth"
          class="viewport"
          [ngStyle]="{
            width: scrollableWidth,
            height: scrollableHeight
          }"
          id="matrix-scrollable-container"
        >
          <!-- column -->
          <div *cdkVirtualFor="let col of decorRendered; index as i" class="example-item table-col w-12">
            <!-- columns headings -->
            <div class="dlv-matrix-cell shadow-headline-decor border-b border-black relative z-50 bg-white transition-transform ease-in-out table-scrollable-heading" [ngClass]="!openDecor ? 'h-40' : 'h-104'">
              <div class="pt-3 h-full items-center inline-flex w-12 justify-start rotate-180 truncate writing-mode-tb">
                <div class="h-full mb-2">
                  <img class="headline-decor-icon w-6 h-6 mb-2 mx-3 inline select-none" src="{{ col.mainimagesmall }}" />
                  <span *ngIf="!openDecor" class="text-sm inline-block relative w-6 mx-3 select-none align-middle decor-text overflow-hidden text-ellipsis" [ngClass]="col.newDecor && col.newDecor.key === '1' ? 'max-h-21' : ' max-h-32'">
                    {{ col.decornumber }} {{ decors[getIndexForData(col.key, 'decor')].decorname }}
                  </span>

                  <span *ngIf="openDecor" class="text-sm inline-block relative w-6 mx-3 select-none align-middle decor-text">
                    {{ col.decornumber }} {{ decors[getIndexForData(col.key, 'decor')].decorname }}
                  </span>

                  <span *ngIf="col.newDecor && col.newDecor.key === '1'" class="border border-black border-solid rounded-sm text-xs py-1 mt-2 float-right right-3.5 relative uppercase">{{ labelService.getLabel('dlv.filter.new_decor_label') }}</span>
                </div>
              </div>
            </div>
            <!-- columns content -->
            <ng-container *ngFor="let row of productRendered">
              <div
                class="w-full h-16 justify-center items-center inline-flex relative dlv-matrix-cell"
                [ngClass]="{
                  'bg-gray-100 dlv-matrix-subcell': openRows.has(row.key),
                  'bg-white': !openRows.has(row.key)
                }"
                (mouseenter)="highlightAllClasses(row.key)"
                (mouseleave)="removeHighlightAllClasses(row.key)"
              >
                <div class="z-10 icon-for-tooltip" attr.data-row-id="{{ row.key }}">
                  <div class="w-2.5 h-2.5" *ngIf="getAvailabilityIcon(['root', 'producttypeSV', row.key, 'decor', col.key]) !== ''">
                    <mat-icon class="w-2.5 h-2.5 availability-icon-svg" [svgIcon]="getAvailabilityIcon(['root', 'producttypeSV', row.key, 'decor', col.key])" [ngClass]="getAvailabilityIconColorClass(['root', 'producttypeSV', row.key, 'decor', col.key])"></mat-icon>
                  </div>
                </div>
                <span *ngIf="getAvailabilityIcon(['root', 'producttypeSV', row.key, 'decor', col.key]) !== ''" [class]="{ 'tooltip-availability': i < decorRendered.length - 4 || decorRendered.length <= 4, 'tooltip-availability-left': i >= decorRendered.length - 4 && decorRendered.length > 4 }">
                  <mat-icon class="w-2.5 h-2.5 availability-icon-svg relative tooltip-availability-icon tooltip-hidden md:inline-block" [svgIcon]="getAvailabilityIcon(['root', 'producttypeSV', row.key, 'decor', col.key])" [ngClass]="getAvailabilityIconColorClass(['root', 'producttypeSV', row.key, 'decor', col.key])"></mat-icon>
                  <span class="relative md:-top-1 bg-gray-900">{{ getAvailabilityLabel(['root', 'producttypeSV', row.key, 'decor', col.key]) }}</span>
                </span>
                <div class="absolute w-12 h-px bg-gray-200"></div>
                <div class="absolute h-16 w-px bg-gray-200"></div>
              </div>
              <ng-container *ngIf="openRows.has(row.key)">
                <div (mouseenter)="highlightAllClasses(row.key, childRow.key.split(',')[1])" (mouseleave)="removeHighlightAllClasses(row.key, childRow.key.split(',')[1])" attr.data-row-id="{{ row.key }}-{{ childRow.key.split(',')[1] }}" *ngFor="let childRow of row.children; trackBy: trackByKey" class="dlv-matrix-cell dlv-matrix-subcell w-full h-16 justify-center items-center inline-flex relative bg-gray-100">
                  <div class="z-10 icon-for-tooltip" attr.data-row-id="{{ row.key }}-{{ childRow.key.split(',')[1] }}">
                    <div class="w-2.5 h-2.5">
                      <mat-icon class="w-2.5 h-2.5 availability-icon-svg" [svgIcon]="getAvailabilityIcon(['root', 'producttypeSV', row.key, 'generated_pivoting_value', childRow.key, 'decor', col.key])" [ngClass]="getAvailabilityIconColorClass(['root', 'producttypeSV', row.key, 'generated_pivoting_value', childRow.key, 'decor', col.key])"></mat-icon>
                    </div>
                  </div>
                  <span *ngIf="getAvailabilityIcon(['root', 'producttypeSV', row.key, 'generated_pivoting_value', childRow.key, 'decor', col.key]) !== ''" [class]="{ 'tooltip-availability': i < decorRendered.length - 4 || decorRendered.length <= 4, 'tooltip-availability-left': i >= decorRendered.length - 4 && decorRendered.length > 4 }">
                    <mat-icon class="w-2.5 h-2.5 availability-icon-svg relative tooltip-availability-icon tooltip-hidden" [svgIcon]="getAvailabilityIcon(['root', 'producttypeSV', row.key, 'generated_pivoting_value', childRow.key, 'decor', col.key])" [ngClass]="getAvailabilityIconColorClass(['root', 'producttypeSV', row.key, 'generated_pivoting_value', childRow.key, 'decor', col.key])"></mat-icon>
                    <span class="relative md:-top-1 bg-gray-900">{{ getAvailabilityLabel(['root', 'producttypeSV', row.key, 'generated_pivoting_value', childRow.key, 'decor', col.key]) }}</span>
                  </span>
                  <div class="absolute w-12 h-px bg-gray-200"></div>
                  <div class="absolute h-16 w-px bg-gray-200"></div>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngFor="let row of additionalRows">
              <div class="dlv-matrix-cell w-full h-16 justify-center items-center inline-flex relative bg-white">
                <div class="z-10">
                  <div class="w-2.5 h-2.5"></div>
                </div>
                <div class="absolute w-12 h-px bg-gray-200"></div>
                <div class="absolute h-16 w-px bg-gray-200"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="additionalRows.length > 0">
              <div class="dlv-matrix-cell w-full h-16 justify-center items-center inline-flex relative bg-white">
                <div class="z-10">
                  <div class="w-2.5 h-2.5"></div>
                </div>
                <div class="absolute w-12 h-px bg-gray-200"></div>
                <div class="absolute h-16 w-px bg-gray-200"></div>
              </div>
            </ng-container>
          </div>

          <div *ngFor="let col of additionalColumns" class="example-item table-col w-12">
            <!-- columns headings -->
            <div class="dlv-matrix-cell shadow-headline-decor border-b border-black relative z-50 bg-white transition-transform duration-50 ease-in-out table-scrollable-heading" [ngClass]="!openDecor ? 'h-40' : 'h-104'">
              <div class="pt-4 h-full items-center inline-flex w-12 justify-start rotate-180 truncate writing-mode-tb"></div>
            </div>
            <!-- columns content -->
            <ng-container *ngFor="let row of productRendered; trackBy: trackByKey">
              <div
                class="dlv-matrix-cell w-full h-16 justify-center items-center inline-flex relative"
                [ngClass]="{
                  'bg-gray-100 dlv-matrix-subcell': openRows.has(row.key),
                  'bg-white': !openRows.has(row.key)
                }"
              >
                <div class="z-10">
                  <div class="w-2.5 h-2.5"></div>
                </div>
                <div class="absolute w-12 h-px bg-gray-200"></div>
                <div class="absolute h-16 w-px bg-gray-200"></div>
              </div>
              <ng-container *ngIf="openRows.has(row.key)">
                <div *ngFor="let childRow of row.children; trackBy: trackByKey" class="dlv-matrix-cell dlv-matrix-subcell w-full h-16 justify-center items-center inline-flex relative bg-gray-100">
                  <div class="z-10" attr.data-row-id="{{ row.key }}-{{ childRow.key.split(',')[1] }}">
                    <div class="w-2.5 h-2.5"></div>
                  </div>
                  <div class="absolute w-12 h-px bg-gray-200"></div>
                  <div class="absolute h-16 w-px bg-gray-200"></div>
                </div>
              </ng-container>
            </ng-container>

            <ng-container *ngFor="let row of additionalRows">
              <div class="w-full h-16 justify-center items-center inline-flex relative bg-white dlv-matrix-cell">
                <div class="z-10">
                  <div class="w-2.5 h-2.5"></div>
                </div>
                <div class="absolute w-12 h-px bg-gray-200"></div>
                <div class="absolute h-16 w-px bg-gray-200"></div>
              </div>
            </ng-container>
            <ng-container *ngIf="additionalRows.length > 0">
              <div class="w-full h-16 justify-center items-center inline-flex relative bg-white dlv-matrix-cell">
                <div class="z-10">
                  <div class="w-2.5 h-2.5"></div>
                </div>
                <div class="absolute w-12 h-px bg-gray-200"></div>
                <div class="absolute h-16 w-px bg-gray-200"></div>
              </div>
            </ng-container>
          </div>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  </div>
</div>
