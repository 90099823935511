import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DlvAvailabilityItem } from 'src/app/types/dlvAvailabilityItem.type';

@Component({
  selector: 'dlv-app-pivot-additional-legend',
  templateUrl: './pivot-additional-legend.component.html',
  styleUrls: ['./pivot-additional-legend.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PivotAdditionalLegendComponent implements OnInit {
  @Input() public availabilities!: DlvAvailabilityItem[];
  public legendItems: { color: string; icon: string }[] = [];
  constructor() {}

  ngOnInit(): void {
    this.legendItems = [
      { color: 'red', icon: 'circle-full' },
      { color: 'red', icon: 'circle-half' },
      { color: 'red', icon: 'circle-outline' },
      { color: 'black', icon: 'circle-outline' },
    ];
  }
}
