<div (click)="closeLegendModal()" [className]="openedModal ? 'modal-open fixed inset-0 h-screen flex items-center justify-center bg-black-transparent z-100 xl:pt-20' : 'modal-open fixed inset-0 h-screen flex items-center justify-center bg-black-transparent z-100 xl:pt-20 hidden'">
  <div class="relative max-h-screen flex m-10" (click)="$event.stopPropagation()">
    <div class="overflow-auto max-h-screen w-full bg-white border-t-4 border-solid border-red-600">
      <span class="p-2 m-3 float-right cursor-pointer" (click)="closeLegendModal()">
        <svg id="close" class="w-3 h-3" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path d="M1.72104534,0.46097588 C1.49595852,0.235901847 1.16788459,0.147999698 0.860407532,0.230383247 C0.552932891,0.312766148 0.312770001,0.552915393 0.23038389,0.860367077 C0.158296609,1.12938518 0.216587424,1.41417164 0.383080318,1.6319501 L0.460994379,1.72093987 L6.7398056,8.00013834 L0.462727622,14.2768158 C0.125188677,14.6264396 0.130147634,15.182053 0.473873377,15.5255972 C0.788960984,15.8405184 1.28213261,15.8707086 1.62945688,15.6152932 L1.72032226,15.5385526 L7.99986982,9.25973384 L14.2786858,15.5389371 C14.6266495,15.886963 15.1908813,15.8870294 15.5389269,15.5390855 C15.8579631,15.2201427 15.8846053,14.7194961 15.6188239,14.3702158 L15.5390879,14.2789606 L9.2599047,7.99976216 L15.5362921,1.72377403 C15.8739126,1.37422967 15.8690841,0.818615871 15.5254398,0.474991046 C15.2104268,0.159995948 14.717263,0.129689242 14.3699044,0.384996121 L14.2790286,0.461707293 L7.99984468,6.74016248 L1.72104534,0.46097588 Z" /></svg>
      </span>
      <div class="overflow-visible md:overflow-hidden lg:overflow-hidden m-6 relative mt-10" *ngIf="openedModal">
        <div class="text-center relative left-2">
          <mat-icon svgIcon="questionmark" class="inline w-5 h-5 m-1 ml-2 mt-0 text-red-600"></mat-icon>
          <p class="font-bold mb-8 text-black text-base uppercase relative -top-1 -left-2.5 md:left-0">{{ labelService.getLabel('dlv.legend') }}</p>
        </div>
        <div class="float-right">
          <p class="text-xs font-bold mb-2">{{ labelService.getLabel('dlv.availability') }}</p>
          <ul class="">
            <ng-container *ngFor="let item of legendItems; index as i; let last = last">
              <li *ngIf="availabilities[i] && availabilities[i].label" class="mr-4 pb-4 flex" [ngClass]="last ? 'border-b border-gray-300 border-solid' : ''">
                <span class="px-2 py-0.5 border border-solid border-gray-300 mr-2"> <mat-icon class="legend-icon" [class]="item.color === 'red' ? 'text-red-600' : ''" svgIcon="{{ item.icon }}" svgIcon="{{ item.icon }}"></mat-icon> </span><span *ngIf="availabilities[i] && availabilities[i].label" class="text-xs top-2 relative">{{ availabilities[i].label }}</span>
              </li>
            </ng-container>
          </ul>
          <p class="text-xs font-bold mb-2 mt-3">{{ labelService.getLabel('dlv.product_info') }}</p>
          <ul class="">
            <li class="mr-4 pb-4 flex">
              <span class="p-1 pb-0 border border-solid border-gray-300 mr-2">
                <mat-icon class="legend-icon-arrow" svgIcon="thickness"></mat-icon>
              </span>
              <span class="text-xs top-2 relative">{{ labelService.getLabel('dlv.width_length') }}</span>
            </li>
            <li class="mr-4 pb-4 flex">
              <span class="p-1 pb-0 border border-solid border-gray-300 mr-2">
                <mat-icon class="legend-icon-arrow" svgIcon="length"></mat-icon>
              </span>
              <span class="text-xs top-2 relative">{{ labelService.getLabel('dlv.thickness') }}</span>
            </li>
          </ul>

          <p class="text-xs font-bold mb-2 mt-3">{{ labelService.getLabel('dlv.edge_info') }}</p>
          <ul class="">
            <li class="mr-4 pb-4 flex">
              <span class="p-1 pb-0 border border-solid border-gray-300 mr-2"> 
                <mat-icon class="legend-icon-arrow" svgIcon="thickness"></mat-icon>
              </span>
              <span class="text-xs top-2 relative">{{ labelService.getLabel('dlv.width') }}</span>
            </li>
            <li class="mr-4 pb-4 flex">
              <span class="p-1 pb-0 border border-solid border-gray-300 mr-2">
                <mat-icon class="legend-icon-arrow" svgIcon="length"></mat-icon>
              </span>
              <span class="text-xs top-2 relative">{{ labelService.getLabel('dlv.length') }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
