import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LabelService } from 'src/app/services/label.service';

const COL_WIDTH = 48;
const TITLE_COL_WIDTH_OPENED = 320;
const TITLE_COL_WIDTH_CLOSED = 112;

@Component({
  selector: 'dlv-app-pivot-table-empty',
  templateUrl: './pivot-table-empty.component.html',
  styleUrls: ['./pivot-table-empty.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PivotTableEmptyComponent implements OnInit {
  public cols: number[] = [];
  constructor(public labelService: LabelService) {}

  ngOnInit(): void {
    let width = window.innerWidth - TITLE_COL_WIDTH_OPENED;
    if (window.innerWidth < 768) {
      width = window.innerWidth - TITLE_COL_WIDTH_CLOSED;
    }
    const length = Math.floor(width / COL_WIDTH) + 1;
    for (let i = 0; i < length; i++) {
      this.cols.push(i);
    }
  }
}
