import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from './store.service';

@Injectable({
  providedIn: 'root',
})
export class LabelService {
  constructor(
    public translate: TranslateService,
    public storeService: StoreService
  ) {}

  public getLabel(labelId: string): string | undefined {
    const language = this.storeService.getConfig().language;
    const country = this.storeService.getConfig().country;
    const countryLang = language.toLowerCase() + '-' + country.toUpperCase();
    if (
      this.storeService.availableCountryTranslations().includes(countryLang)
    ) {
      this.translate.use(language.toLowerCase() + '-' + country.toUpperCase());
    } else {
      this.translate.use(language.toLowerCase());
    }

    return this.translate.instant(labelId);
  }
}
