<div *ngIf="(!data || loading) && !error" class="fixed inset-0 h-screen flex items-center justify-center bg-black-transparent z-130 xl:pt-20 overflow-hidden">
  <dlv-app-loading-spinner></dlv-app-loading-spinner>
</div>
<div *ngIf="data && !error" [ngClass]="isPDFLoading ? '' : 'hidden'" class="fixed inset-0 h-screen flex items-center justify-center bg-black-transparent z-110 xl:pt-20 overflow-hidden">
  <dlv-app-loading-spinner-pdf (pdfAbort)="abortPDF()"></dlv-app-loading-spinner-pdf>
</div>
<div *ngIf="!data && !error" class="overflow-hidden">
  <dlv-app-pivot-header></dlv-app-pivot-header>
  <dlv-app-pivot-table-empty></dlv-app-pivot-table-empty>
</div>

<div *ngIf="data && !error">
  <dlv-app-pivot-header></dlv-app-pivot-header>
</div>

<div *ngIf="data && !error" [ngClass]="isPDFLoading ? 'pointer-events-none' : ''">
  <dlv-app-floating-button [setFilters]="activeFilters" [filterResults]="filterResults"></dlv-app-floating-button>
  <dlv-app-pdf-button (pdfExport)="triggerPDFExport()" [filterResults]="filterResults"></dlv-app-pdf-button>
</div>
<div *ngIf="!error" [class]="data ? '' : 'hidden'" class="bg-gray-100 border-b border-gray-400 border-solid">
  <dlv-app-pivot-filters [allFacets]="allFacets" [facets]="facets" [data]="data" (facetInit)="facetInit($event)" (results)="results($event)" (setFilters)="setFilters($event)"> </dlv-app-pivot-filters>
  <dlv-app-pivot-additional-legend *ngIf="data" id="additional-legend" class="block overflow-auto" [availabilities]="availabilities"></dlv-app-pivot-additional-legend>
</div>

<div *ngIf="data && !error">
  <dlv-app-pivot-legend [openedModal]="openedModal" [availabilities]="availabilities" (modalClosed)="closeModal()"></dlv-app-pivot-legend>
  <dlv-app-pivot-table-scrollable [PDFExported]="PDFExported" [data]="data" [productDetailsXY]="productDetailsXY" [productDetailsZ]="productDetailsZ" [productDetailsGlues]="productDetailsGlues" [productDetailsLayoutModels]="productDetailsLayoutModels" [productDetailsLayouts]="productDetailsLayouts" [availabilities]="availabilities" [decors]="decors" [products]="products" [results]="filterResults" (modalOpened)="openModal()" (pdfExport)="exportPDF($event)"></dlv-app-pivot-table-scrollable>
</div>

<div *ngIf="error">
  <dlv-app-error-page></dlv-app-error-page>
</div>
