import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    Messages: Map<string, string>;
    Tracking: any;
    _jts: any;
  }
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
