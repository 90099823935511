export const DLV_INSTANCE_MAPPINGS = [
  {
    key: 'egger-runner-production-1',
    instances: [
      'ad-es',
      'ae-en',
      'af-en',
      'ag-en',
      'al-en',
      'am-en',
      'am-ru',
      'an-en',
      'an-es',
      'ao-en',
      'aq-en',
      'ar-es',
      'at-de',
      'au-en',
      'aw-en',
      'aw-es',
      'az-en',
      'az-ru',
      'az-tr',
      'ba-hr',
      'bb-en',
      'bd-en',
      'be-fr',
      'be-nl',
      'bf-en',
      'bg-bg',
      'bh-en',
      'bi-en',
      'bj-en',
      'bn-en',
      'bo-es',
      'br-en',
      'br-es',
      'bs-en',
      'bt-en',
      'bw-en',
      'by-ru',
      'bz-en',
      'bz-es',
      'ca-en',
      'ca-fr',
      'cd-en',
      'cf-en',
      'cg-en',
      'ch-de',
      'ch-fr',
      'ci-en',
      'cl-es',
      'cm-en',
      'cn-zh',
      'co-es',
      'cr-es',
      'cu-es',
      'cv-en',
      'cx-en',
      'cy-en',
      'cz-cs',
      'cz-en',
      'de-de',
      'dj-en',
      'dk-en',
      'dm-en',
      'do-es',
      'dz-en',
      'ec-es',
      'ee-en',
      'ee-ru',
      'eg-en',
      'eh-en',
      'er-en',
      'es-es',
      'et-en',
      'fi-en',
      'fj-en',
      'fm-en',
      'fo-en',
      'fr-fr',
      'ga-en',
      'gb-en',
      'gd-en',
      'ge-en',
      'ge-ru',
      'gf-en',
    ],
  },
  {
    key: 'egger-runner-production-2',
    instances: [
      'gf-es',
      'gh-en',
      'gm-en',
      'gn-en',
      'gp-en',
      'gq-en',
      'gr-el',
      'gr-en',
      'gt-es',
      'gw-en',
      'gy-en',
      'hk-zh',
      'hm-en',
      'hn-es',
      'hr-hr',
      'ht-en',
      'hu-en',
      'hu-hu',
      'id-en',
      'ie-en',
      'il-en',
      'in-en',
      'io-en',
      'iq-en',
      'ir-en',
      'is-en',
      'it-de',
      'it-it',
      'jm-en',
      'jo-en',
      'jp-en',
      'ke-en',
      'kg-ru',
      'kh-en',
      'ki-en',
      'km-en',
      'kn-en',
      'kp-en',
      'kr-en',
      'kw-en',
      'ky-en',
      'kz-ru',
      'la-en',
      'lb-en',
      'lc-en',
      'li-de',
      'lk-en',
      'lr-en',
      'ls-en',
      'lt-en',
      'lt-lt',
      'lt-ru',
      'lu-de',
      'lu-fr',
      'lv-en',
      'lv-ru',
      'ly-en',
      'ma-en',
      'mc-fr',
      'md-ro',
      'md-ru',
      'me-sr',
      'mg-en',
      'mh-en',
      'mk-sr',
      'ml-en',
      'mm-en',
      'mn-en',
      'mo-zh',
      'mq-en',
      'mr-en',
      'mt-en',
      'mu-en',
      'mv-en',
      'mw-en',
      'mx-es',
      'my-en',
      'mz-en',
      'na-en',
      'nc-en',
      'ne-en',
      'nf-en',
      'ng-en',
    ],
  },
  {
    key: 'egger-runner-production-3',
    instances: [
      'ni-es',
      'nl-nl',
      'no-en',
      'np-en',
      'nr-en',
      'nu-en',
      'nz-en',
      'om-en',
      'pa-es',
      'pe-es',
      'pf-en',
      'pg-en',
      'ph-en',
      'pk-en',
      'pl-pl',
      'pn-en',
      'pr-es',
      'ps-en',
      'pt-pt',
      'pw-en',
      'py-es',
      'qa-en',
      're-en',
      're-fr',
      'ro-ro',
      'rs-sr',
      'ru-ru',
      'rw-en',
      'sa-en',
      'sb-en',
      'sc-en',
      'sd-en',
      'se-en',
      'sg-en',
      'si-sl',
      'sk-cs',
      'sl-en',
      'sm-it',
      'sn-en',
      'so-en',
      'sr-en',
      'sr-es',
      'ss-en',
      'st-en',
      'sv-es',
      'sy-en',
      'sz-en',
      'td-en',
      'tg-en',
      'th-en',
      'tj-ru',
      'tk-en',
      'tl-en',
      'tm-ru',
      'tn-en',
      'to-en',
      'tr-tr',
      'tt-en',
      'tv-en',
      'tw-en',
      'tz-en',
      'ua-ru',
      'ua-uk',
      'ug-en',
      'us-en',
      'uy-es',
      'uz-ru',
      'va-it',
      'vc-en',
      've-es',
      'vg-en',
      'vn-en',
      'vu-en',
      'wf-en',
      'ws-en',
      'xk-en',
      'xx-de',
      'xx-es',
      'ye-en',
      'za-en',
      'zm-en',
      'zw-en',
    ],
  },
];
