import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PivotResponse } from '@dcupl/common';
import { DcuplFacet } from '@dcupl/common';
import { DlvCol } from 'src/app/types/dlvCol.type';
import { DlvIdDictionary } from 'src/app/types/dlvIdDictionary.type';
import { DlvIdDictionaryNumber } from 'src/app/types/dlvIdDictionaryNumber.type';
import { DlvFacetItem } from 'src/app/types/dlvFacetItem.type';
import { DlvFacetObject } from 'src/app/types/dlvFacetObject.type';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'dlv-app-pivot-filters',
  templateUrl: './pivot-filters.component.html',
  styleUrls: ['./pivot-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PivotFiltersComponent implements OnChanges, OnInit {
  @Input() public facets: DlvFacetItem[] = [];
  @Input() public allFacets: DlvFacetItem[] = [];
  @Input() public data!: PivotResponse | undefined;

  @Output() facetInit = new EventEmitter<DlvFacetObject[]>();
  @Output() results = new EventEmitter<number>();
  @Output() setFilters = new EventEmitter<number>();

  @ViewChild('decorSearch') searchInput: ElementRef | undefined;
  @ViewChild('decorSearchMobile') searchInputMobile: ElementRef | undefined;

  public openFilters = new Set<string>();
  public activeStockFilter = false;
  public activeNewDecorFilter = false;
  public activeFavoriteFilter = false;
  public numberOfFiltersFirstLevel = 0;
  public filterValues: DlvFacetObject[] = [];
  public searchValue = '';
  public currentSearchInput = '';
  public filterIcons: DlvIdDictionary = {};
  public filtersTemporary: DcuplFacet[] = [];
  public filterItemTemporary: DlvFacetItem[] = [];
  public filterChildren: DlvFacetObject[] = [];
  public filterResults = 0;
  public activeFiltersByKey: DlvIdDictionaryNumber = {};

  constructor(public labelService: LabelService) {}

  ngOnInit(): void {
    this.filterIcons['producttype'] = 'producttype';
    this.filterIcons['decor_decorgroup'] = 'decortype';
    this.filterIcons['decor_structuretype'] = 'structure';
    this.filterIcons['decor_color'] = 'colors';
    this.filterIcons['producttype_feature'] = 'function';
    this.checkFilterParamsInURL();
    this.initFilters();

    const url = new URL(window.location.href);
    if (url.toString().includes('openFilterMobile=true')) {
      url.searchParams.delete('openFilterMobile');
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }

    setTimeout(() => {
      this.facetInit.emit(this.filterValues);
    }, 900);
  }

  ngOnChanges(): void {
    this.initFilterChildren();

    this.results.emit(this.searchResults());

    setTimeout(() => {
      if (this.searchInput && this.searchInput.nativeElement) {
        const url = new URL(window.location.href);
        this.searchInput.nativeElement.value = url.searchParams.get('search');
        this.currentSearchInput = this.searchInput.nativeElement.value;
      }
      if (this.searchInputMobile && this.searchInputMobile.nativeElement) {
        const url = new URL(window.location.href);
        this.searchInputMobile.nativeElement.value =
          url.searchParams.get('search');
        this.currentSearchInput = this.searchInputMobile.nativeElement.value;
      }
      window.scrollBy(0, 1);
    }, 300);
  }

  public isFilterActive(key: string): boolean {
    if (this.facets.filter((e) => e.key === key).length > 0) {
      for (let i = 0; i < this.facets.length; i++) {
        if (this.facets[i].key == key && this.facets[i].facets.length > 0) {
          return true;
        }
      }
    } else {
      for (let i = 0; i < this.facets.length; i++) {
        if (this.facets[i].facets.filter((e) => e.key === key).length > 0) {
          return true;
        }
      }
    }
    return false;
  }

  public filterByFavorites(): void { 
    const favs = window.localStorage.getItem("decorFavorites");
    let favorites = [];
    if(favs) {
      favorites = JSON.parse(favs);
    }
    this.filterValues.push({
      key: 'decor',
      facets: favorites,
    });
  }

  public searchDecor(value: string, type = ''): void {
    if (value !== '' && value.length > 1) {
      this.deleteAllFilters(true, true);
      const url = new URL(window.location.href);
      url.searchParams.append('search', value);
      window.history.pushState({ path: url.toString() }, '', url.toString());

      if (url.toString().includes('openFilterMobile=true')) {
        document
          .getElementById('additional-legend')
          ?.classList.remove('hidden');
        url.searchParams.delete('openFilterMobile');
        window.history.pushState({ path: url.toString() }, '', url.toString());
      }
      if (window._jts && window._jts.push) {
        window._jts.push({
          track: 'event',
          group: 'dlv_search',
          egg_track_id: value,
          egg_page_url: document.location.href,
          egg_page_title: document.title,
          egg_page_referrer: document.referrer,
        });
      }
      console.log('dlv_search: ' + value);

      setTimeout(() => {
        this.checkFilterParamsInURL();
        this.initFilters();
        this.facetInit.emit(this.filterValues);
        this.results.emit(this.searchResults());
        this.currentSearchInput = value;
        if (type === 'mobile') {
          document
            .getElementById('filter-mobile')
            ?.classList.add('filter-hidden');
          document
            .getElementById('egger-dlv-matrix')
            ?.classList.remove('hidden');
          document.getElementById('dlv-header')?.classList.remove('hidden');
          document.getElementById('filter-results')?.classList.remove('hidden');
          document
            .getElementById('floating-button-mobile')
            ?.classList.remove('hidden');
        }
      }, 1000);
    }
  }

  public searchResults(): number {
    this.filterResults = this.getColumns().length;
    return this.filterResults;
  }

  public deleteSearchInput(type = ''): void {
    const url = new URL(window.location.href);
    url.searchParams.delete('search');
    window.history.pushState({ path: url.toString() }, '', url.toString());
    if (url.toString().includes('openFilterMobile=true')) {
      document.getElementById('additional-legend')?.classList.remove('hidden');
      url.searchParams.delete('openFilterMobile');
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }
    if (this.searchInputMobile) {
      this.searchInputMobile.nativeElement.value = '';
    }

    for (let i = 0; i < this.filterValues.length; i++) {
      if (this.filterValues[i].key === 'search') {
        this.filterValues.splice(i, 1);
      }
    }

    if (type === 'mobile') {
      document.getElementById('filter-mobile')?.classList.add('filter-hidden');
      document.getElementById('egger-dlv-matrix')?.classList.remove('hidden');
      document.getElementById('filter-results')?.classList.remove('hidden');
      document.getElementById('dlv-header')?.classList.remove('hidden');
      document
        .getElementById('floating-button-mobile')
        ?.classList.remove('hidden');
    }
    this.initFilters();
    this.facetInit.emit(this.filterValues);
    this.results.emit(this.searchResults());
  }

  public hasSearchInput(): boolean {
    if (this.searchInput && this.searchInput.nativeElement.value != '') {
      return true;
    }
    return false;
  }

  public hasSearchInputMobile(): boolean {
    if (
      this.searchInputMobile &&
      this.searchInputMobile.nativeElement.value != ''
    ) {
      return true;
    }
    return false;
  }

  private getUrlParameter(paramName: string): string[] {
    const search = new URLSearchParams(window.location.search);
    return !search ? [] : search.getAll(paramName);
  }

  private checkFilterParamsInURL(): void {
    this.filterValues = [];
    const url = new URL(window.location.href);
    if (this.getUrlParameter('newDecors').length > 0) {
      this.activeNewDecorFilter = true;
      this.filterValues.push({
        key: 'decor_newDecor',
        facets: ['1'],
      });
    }

    if (this.getUrlParameter('favorites').length > 0) {
      this.activeFavoriteFilter = true;
      this.filterByFavorites();
    }

    if (this.getUrlParameter('inStock').length > 0) {
      this.activeStockFilter = true;
      this.filterValues.push({
        key: 'availablility',
        facets: ['stock', 'available'],
      });
    }

    if (this.getUrlParameter('producttype').length > 0) {
      if (this.getUrlParameter('producttype')[0] === '') {
        url.searchParams.delete('producttype');
        window.history.pushState({ path: url.toString() }, '', url.toString());
      } else {
        const items = this.getUrlParameter('producttype')[0].split('__');
        this.filterValues.push({
          key: 'producttype',
          facets: items,
        });
      }
    }

    if (this.getUrlParameter('search').length > 0) {
      const value = this.getUrlParameter('search');
      this.filterValues.push({
        key: 'search',
        facets: value,
      });
    }

    if (this.getUrlParameter('decor').length > 0) {
      if (this.getUrlParameter('decor')[0] === '') {
        url.searchParams.delete('decor');
        window.history.pushState({ path: url.toString() }, '', url.toString());
      } else {
        const items = this.getUrlParameter('decor')[0].split('__');
        this.filterValues.push({
          key: 'decor',
          facets: items,
        });
      }
    }

    if (this.getUrlParameter('decor_decorgroup').length > 0) {
      if (this.getUrlParameter('decor_decorgroup')[0] === '') {
        url.searchParams.delete('decor_decorgroup');
        window.history.pushState({ path: url.toString() }, '', url.toString());
      } else {
        const items = this.getUrlParameter('decor_decorgroup')[0].split('__');
        this.filterValues.push({
          key: 'decor_decorgroup',
          facets: items,
        });
      }
    }

    if (this.getUrlParameter('decor_structuretype').length > 0) {
      if (this.getUrlParameter('decor_structuretype')[0] === '') {
        url.searchParams.delete('decor_structuretype');
        window.history.pushState({ path: url.toString() }, '', url.toString());
      } else {
        const items = this.getUrlParameter('decor_structuretype')[0].split(
          '__'
        );
        this.filterValues.push({
          key: 'decor_structuretype',
          facets: items,
        });
      }
    }

    if (this.getUrlParameter('decor_color').length > 0) {
      if (this.getUrlParameter('decor_color')[0] === '') {
        url.searchParams.delete('decor_color');
        window.history.pushState({ path: url.toString() }, '', url.toString());
      } else {
        const items = this.getUrlParameter('decor_color')[0].split('__');
        this.filterValues.push({
          key: 'decor_color',
          facets: items,
        });
      }
    }

    if (this.getUrlParameter('producttype_feature').length > 0) {
      if (this.getUrlParameter('producttype_feature')[0] === '') {
        url.searchParams.delete('producttype_feature');
        window.history.pushState({ path: url.toString() }, '', url.toString());
      } else {
        const items = this.getUrlParameter('producttype_feature')[0].split(
          '__'
        );
        this.filterValues.push({
          key: 'producttype_feature',
          facets: items,
        });
      }
    }

    if (this.getUrlParameter('decor_style').length > 0) {
      if (this.getUrlParameter('decor_style')[0] === '') {
        url.searchParams.delete('decor_style');
        window.history.pushState({ path: url.toString() }, '', url.toString());
      } else {
        const items = this.getUrlParameter('decor_style')[0].split('__');
        this.filterValues.push({
          key: 'decor_style',
          facets: items,
        });
      }
    }
  }

  private initFilters(): void {
    this.numberOfFiltersFirstLevel = this.filterValues.length;
    if (this.activeStockFilter) {
      this.numberOfFiltersFirstLevel -= 1;
    }
    this.setFilters.emit(this.numberOfFiltersFirstLevel);
  }

  public initFilterChildren(): void {
    this.filterChildren = [];
    for (let i = 0; i < this.facets.length; i++) {
      for (let j = 0; j < this.facets[i].facets.length; j++) {
        if (this.facets[i].facets[j].entry['parent']) {
          if (this.filterChildren.length === 0) {
            this.filterChildren.push({
              key: this.facets[i].facets[j].entry['parent'].key,
              facets: [this.facets[i].facets[j].key],
            });
          } else {
            let a = false;
            for (let k = 0; k < this.filterChildren.length; k++) {
              if (
                this.filterChildren[k].key ===
                this.facets[i].facets[j].entry['parent'].key
              ) {
                this.filterChildren[k].facets.push(
                  this.facets[i].facets[j].key
                );
                a = true;
                break;
              }
            }
            if (!a) {
              this.filterChildren.push({
                key: this.facets[i].facets[j].entry['parent'].key,
                facets: [this.facets[i].facets[j].key],
              });
            }
          }
        }
      }
    }
  }

  public activeFilters(key: string, item: DlvFacetItem): number {
    if (this.activeFiltersByKey[key] === undefined) {
      this.activeFiltersByKey[key] = 0;
    }
    this.activeFiltersByKey[key] = 0;
    for (let i = 0; i < item.facets.length; i++) {
      if (
        this.activeChildrenNumber(item.facets[i].key) > 0 &&
        item.facets[i].entry &&
        !item.facets[i].entry['parent']
      ) {
        this.activeFiltersByKey[key] += this.activeChildrenNumber(
          item.facets[i].key
        );
      }
    }
    if (this.activeFiltersByKey[key] > 0) {
      return this.activeFiltersByKey[key];
    }

    for (let i = 0; i < this.filterValues.length; i++) {
      if (this.filterValues[i].key === key) {
        this.activeFiltersByKey[key] = this.filterValues[i].facets.length;
        return this.filterValues[i].facets.length;
      }
    }
    return 0;
  }

  public isActiveFilter(key: string): boolean {
    if (this.openFilters.has(key)) {
      return true;
    }
    return false;
  }

  public allChildrenActive(key: string): boolean {
    let el = document.getElementById(key);
    const siblings: HTMLElement[] = [];
    let hasMoreChildren = true;
    while (hasMoreChildren) {
      if (el?.nextSibling) {
        if (el.nextSibling.firstChild) {
          siblings.push(el.nextSibling.firstChild as HTMLElement);
        }
        el = el.nextSibling as HTMLElement;
      } else {
        hasMoreChildren = false;
      }
    }

    if (siblings.length === 0) {
      return false;
    }

    let a = true;

    for (let i = 0; i < siblings.length; i++) {
      const s = siblings[i];
      if (s && s.classList.contains('checked')) {
      } else {
        a = false;
      }
    }
    return a;
  }

  public hasChildren(key: string): boolean {
    let el = document.getElementById(key);
    const siblings: HTMLElement[] = [];
    let hasMoreChildren = true;
    while (hasMoreChildren) {
      if (el?.nextSibling) {
        if (el.nextSibling.firstChild) {
          siblings.push(el.nextSibling.firstChild as HTMLElement);
        }
        el = el.nextSibling as HTMLElement;
      } else {
        hasMoreChildren = false;
      }
    }

    if (siblings.length == 0) {
      return false;
    }

    return true;
  }

  public activeChildrenNumber(key: string): number {
    let el = document.getElementById(key);
    const siblings: HTMLElement[] = [];
    let hasMoreChildren = true;
    while (hasMoreChildren) {
      if (el?.nextSibling) {
        if (el.nextSibling.firstChild) {
          siblings.push(el.nextSibling.firstChild as HTMLElement);
        }
        el = el.nextSibling as HTMLElement;
      } else {
        hasMoreChildren = false;
      }
    }

    if (siblings.length === 0) {
      return 0;
    }

    let counter = 0;
    for (let i = 0; i < siblings.length; i++) {
      const s = siblings[i];
      if (s && s.classList.contains('checked')) {
        counter += 1;
      }
    }
    return counter;
  }

  public deactivateParent(key: string): void {
    const el = document.getElementById(key)?.firstChild as HTMLElement;
    if (el?.classList.contains('checked')) {
      el?.classList.remove('checked');
    } else if (
      this.activeChildrenNumber(key) == 0 &&
      el?.classList.contains('checked-half')
    ) {
      el?.classList.remove('checked-half');
    } else if (this.hasActiveChild(key)) {
      el?.classList.add('checked-half');
    }
  }

  public deactivateParentKey(key: string): void {
    const el = document.getElementById(key)?.firstChild as HTMLElement;
    if (el?.classList.contains('checked')) {
      this.deactivateAllChildren(key);
    }
  }

  public deactivateAllChildren(key: string): void {
    let el = document.getElementById(key);
    const siblings: HTMLElement[] = [];
    let hasMoreChildren = true;
    while (hasMoreChildren) {
      if (el?.nextSibling) {
        for (let i = 0; i < this.filtersTemporary.length; i++) {
          const child = el.nextSibling as HTMLElement;
          if (this.filtersTemporary[i].key === child.id) {
            this.filtersTemporary.splice(i, 1);
          }
        }
        if (el.nextSibling.firstChild) {
          siblings.push(el.nextSibling.firstChild as HTMLElement);
        }
        el = el.nextSibling as HTMLElement;
      } else {
        hasMoreChildren = false;
      }
    }

    if (siblings.length > 0) {
      for (let i = 0; i < siblings.length; i++) {
        const s = siblings[i];
        if (s.classList.contains('checked')) {
          s.classList.remove('checked');
        }
      }
    }
  }

  public toggleFilterCheckbox(key: string, type = 'desktop'): void {
    let el = document.getElementById(key)?.firstChild as HTMLElement;
    if (type == 'mobile') {
      el = document.getElementById(key + '--mobile')?.firstChild as HTMLElement;
    }

    if (el?.classList.contains('checked')) {
      el?.classList.remove('checked');
      el?.classList.remove('checked-half');
    } else {
      el?.classList.add('checked');
    }
    if (el?.classList.contains('checked-half')) {
      el?.classList.remove('checked-half');
      el?.classList.add('checked');
    }
  }

  public hasActiveChild(key: string): boolean {
    if (this.allChildrenActive(key)) {
      return false;
    }
    let hasChildActive = false;
    let hasChildActiveTemporary = false;
    for (let i = 0; i < this.filtersTemporary.length; i++) {
      if (
        this.filtersTemporary[i].entry['parent'] &&
        this.filtersTemporary[i].entry['parent'].key === key
      ) {
        hasChildActiveTemporary = true;
      }
    }

    for (let i = 0; i < this.facets.length; i++) {
      for (let j = 0; j < this.facets[i].facets.length; j++) {
        for (let k = 0; k < this.filterValues.length; k++) {
          if (
            this.filterValues[k].facets.includes(
              this.facets[i].facets[j].key
            ) ||
            (this.facets[i].facets[j].key === 'TSG_1_2x' &&
              this.filterValues[k].facets.includes('TSG_1_2'))
          ) {
            if (
              this.facets[i].facets[j].entry['parent'] &&
              this.facets[i].facets[j].entry['parent'].key === key
            ) {
              hasChildActive = true;
            }
          }
        }
      }
    }

    if (hasChildActiveTemporary || hasChildActive) {
      return true;
    } else if (this.filtersTemporary.length === 0 && hasChildActive) {
      return true;
    }

    return false;
  }

  public isActiveFacet(key: string, type = 'default'): boolean {
    let temporaryFilter = true;
    if (this.filtersTemporary.length > 0) {
      for (let i = 0; i < this.filtersTemporary.length; i++) {
        if (
          this.filtersTemporary[i].key === key &&
          window.location.href.toString().includes(key)
        ) {
          return false;
        } else if (
          this.filtersTemporary[i].key === key &&
          !window.location.href.toString().includes(key)
        ) {
          return true;
        } else {
          temporaryFilter = false;
        }
      }
    } else {
      temporaryFilter = false;
    }

    if (type !== 'child') {
      if (this.allChildrenActive(key)) {
        return true;
      } else if (this.hasActiveChild(key)) {
        return false;
      }
    }

    if (window.location.href.toString().includes(key) && !temporaryFilter) {
      return true;
    } else if (
      window.location.href.toString().includes(key) &&
      temporaryFilter
    ) {
      return true;
    }
    return false;
  }

  public onFacetChange(item: DlvFacetItem, facet: DcuplFacet): void {
    let url = String(new URL(window.location.href));
    if (item.key === 'availablility') {
      item.key = 'availablility';
    }

    if (
      url.toString().includes(`${item.key}=`) &&
      url.toString().includes(facet.key)
    ) {
      if (url.toString().includes(`__${facet.key}`)) {
        url =
          url.toString().split(`__${facet.key}`)[0] +
          url.toString().split(`__${facet.key}`)[1];
      } else if (url.toString().includes(`${facet.key}__`)) {
        url =
          url.toString().split(`${facet.key}__`)[0] +
          url.toString().split(`${facet.key}__`)[1];
      } else {
        url =
          url.toString().split(facet.key)[0] +
          url.toString().split(facet.key)[1];
      }
      if (url.includes(`${item.key}=&`)) {
        url =
          url.toString().split(`${item.key}=&`)[0] +
          url.toString().split(`${item.key}=&`)[1];
      }
      if (url.toString().endsWith('=')) {
        url = url.toString().split(`&${item.key}=`)[0];
      }
    } else if (url.toString().includes(`${item.key}=`)) {
      url = `${`${url.toString().split(`${item.key}=`)[0] + item.key}=${
        facet.key
      }`}__${url.toString().split(`${item.key}=`)[1]}`;
    } else {
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.append(item.key, facet.key);
      window.history.pushState(
        { path: newUrl.toString() },
        '',
        newUrl.toString()
      );
      this.checkFilterParamsInURL();
      this.initFilters();
      return;
    }
    window.history.pushState({ path: url.toString() }, '', url.toString());
    this.checkFilterParamsInURL();
    this.results.emit(this.searchResults());
    this.initFilters();
  }

  private getFacetItemByKey(key: string): DcuplFacet {
    for (let i = 0; i < this.facets.length; i++) {
      for (let j = 0; j < this.facets[i].facets.length; j++) {
        if (this.facets[i].facets[j].key === key) {
          return this.facets[i].facets[j];
        }
      }
    }
    return this.facets[0].facets[0];
  }

  public activateFacet(
    item: DlvFacetItem,
    facet: DcuplFacet,
    type = 'default'
  ): void {
    const url = String(new URL(window.location.href));
    if (type == 'child') {
      this.toggleFilterCheckbox(facet.key);
      this.toggleFilterCheckbox(facet.key, 'mobile');
      if (this.isActiveFacet(facet.entry['parent'].key)) {
        this.deactivateParent(facet.entry['parent'].key);
        this.filterItemTemporary[0] = item;
        for (let k = 0; k < this.filterChildren.length; k++) {
          if (this.filterChildren[k].key === facet.entry['parent'].key) {
            for (let j = 0; j < this.filterChildren[k].facets.length; j++) {
              if (this.filterChildren[k].facets[j] !== facet.key) {
                this.filtersTemporary.push(
                  this.getFacetItemByKey(this.filterChildren[k].facets[j])
                );
              }
            }
          }
        }

        this.filtersTemporary.push(
          this.getFacetItemByKey(facet.entry['parent'].key)
        );
      } else if (
        !url.toString().includes(facet.key) &&
        !this.filtersTemporary.includes(facet) &&
        url.toString().includes(facet.entry['parent'].key)
      ) {
        this.filterItemTemporary[0] = item;
        this.filtersTemporary.push(
          this.getFacetItemByKey(facet.entry['parent'].key)
        );

        for (let k = 0; k < this.filterChildren.length; k++) {
          if (this.filterChildren[k].key === facet.entry['parent'].key) {
            for (let j = 0; j < this.filterChildren[k].facets.length; j++) {
              for (let x = 0; x < this.filterValues.length; x++) {
                for (let y = 0; y < this.filterValues[x].facets.length; y++) {
                  if (
                    this.filterChildren[k].facets[j] ===
                    this.filterValues[x].facets[y]
                  ) {
                    this.filtersTemporary.push(
                      this.getFacetItemByKey(this.filterChildren[k].facets[j])
                    );
                  }
                }
              }
            }
          }
        }
      } else {
        if (url.toString().includes(facet.key)) {
          this.filtersTemporary.push(facet);
          this.filterItemTemporary[0] = item;
          if (this.activeChildrenNumber(facet.entry['parent'].key) == 0) {
            this.deactivateParent(facet.entry['parent'].key);
          }
        } else if (this.allChildrenActive(facet.entry['parent'].key)) {
          this.filterItemTemporary[0] = item;
          this.filtersTemporary = [];
          this.filtersTemporary.push(
            this.getFacetItemByKey(facet.entry['parent'].key)
          );

          for (let k = 0; k < this.filterChildren.length; k++) {
            if (this.filterChildren[k].key === facet.entry['parent'].key) {
              for (let j = 0; j < this.filterChildren[k].facets.length; j++) {
                for (let x = 0; x < this.filterValues.length; x++) {
                  for (let y = 0; y < this.filterValues[x].facets.length; y++) {
                    if (
                      this.filterChildren[k].facets[j] ===
                      this.filterValues[x].facets[y]
                    ) {
                      this.filtersTemporary.push(
                        this.getFacetItemByKey(this.filterChildren[k].facets[j])
                      );
                    }
                  }
                }
              }
            }
          }
        } else {
          this.filterItemTemporary[0] = item;
          let activeItem = false;
          for (let i = 0; i < this.filtersTemporary.length; i++) {
            if (this.filtersTemporary[i].key === facet.key) {
              this.filtersTemporary.splice(i, 1);
              activeItem = true;
            }
          }
          if (!activeItem) {
            this.filtersTemporary.push(facet);
          }
        }
      }
    } else if (type == 'parent') {
      const el = document.getElementById(facet.key)?.firstChild as HTMLElement;
      if (el?.classList.contains('checked')) {
        this.deactivateParentKey(facet.key);
      }
      this.toggleFilterCheckbox(facet.key);
      this.toggleFilterCheckbox(facet.key, 'mobile');
      this.filterItemTemporary[0] = item;

      for (let k = 0; k < this.filterChildren.length; k++) {
        if (this.filterChildren[k].key === facet.key) {
          for (let j = 0; j < this.filterChildren[k].facets.length; j++) {
            for (let i = 0; i < this.filterValues.length; i++) {
              if (this.filterValues[i].key == item.key) {
                for (let x = 0; x < this.filterValues[i].facets.length; x++) {
                  if (
                    this.filterChildren[k].facets[j] ==
                    this.filterValues[i].facets[x]
                  ) {
                    this.filtersTemporary.push(
                      this.getFacetItemByKey(this.filterChildren[k].facets[j])
                    );
                  }
                }
              }
            }
          }
        }
      }

      this.filtersTemporary.push(facet);
    } else {
      this.filterItemTemporary[0] = item;
      let activeItem = false;
      for (let i = 0; i < this.filtersTemporary.length; i++) {
        if (this.filtersTemporary[i].key === facet.key) {
          this.filtersTemporary.splice(i, 1);
          activeItem = true;
        }
      }
      if (!activeItem) {
        this.filtersTemporary.push(facet);
      }
    }

    const uniqueObject: string[] = [];

    for (const i in this.filtersTemporary) {
      uniqueObject.push(this.filtersTemporary[i].key);
    }

    const toFindDuplicates = (uniqueObject: string[]): string[] =>
      uniqueObject.filter(
        (item, index) => uniqueObject.indexOf(item) !== index
      );
    let duplicateElements = toFindDuplicates(uniqueObject);
    for (let i = 0; i < duplicateElements.length; i++) {
      for (let j = 0; j < this.filtersTemporary.length; j++) {
        if (this.filtersTemporary[j].key === duplicateElements[i]) {
          this.filtersTemporary.splice(j, 1);
        }
      }
    }
    for (let i = 0; i < duplicateElements.length; i++) {
      for (let j = 0; j < this.filtersTemporary.length; j++) {
        if (this.filtersTemporary[j].key === duplicateElements[i]) {
          this.filtersTemporary.splice(j, 1);
        }
      }
    }

    duplicateElements = [];
  }

  public applyFilter(type = 'default', searchValue = ''): void {
    this.openFilters.clear();
    const url = new URL(window.location.href);
    if (url.toString().includes('openFilterMobile=true')) {
      document.getElementById('additional-legend')?.classList.remove('hidden');
      url.searchParams.delete('openFilterMobile');
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }
    if (this.filtersTemporary.length == 0 && type == 'mobile') {
      document.getElementById('filter-mobile')?.classList.add('filter-hidden');
      document.getElementById('egger-dlv-matrix')?.classList.remove('hidden');
      document.getElementById('filter-results')?.classList.remove('hidden');
      document
        .getElementById('floating-button-mobile')
        ?.classList.remove('hidden');
      if (searchValue !== '') {
        this.searchDecor(searchValue, 'mobile');
      }
    } else {
      for (let i = 0; i < this.filtersTemporary.length; i++) {
        this.onFacetChange(
          this.filterItemTemporary[0],
          this.filtersTemporary[i]
        );
      }

      if (type == 'mobile') {
        document
          .getElementById('filter-mobile')
          ?.classList.add('filter-hidden');
        document.getElementById('egger-dlv-matrix')?.classList.remove('hidden');
        document.getElementById('filter-results')?.classList.remove('hidden');
        document.getElementById('dlv-header')?.classList.remove('hidden');
        document
          .getElementById('floating-button-mobile')
          ?.classList.remove('hidden');
      }

      for (let i = 0; i < this.filtersTemporary.length; i++) {
        if (!url.toString().includes(`${this.filtersTemporary[i].key}`)) {
          if (window._jts && window._jts.push) {
            window._jts.push({
              track: 'event',
              group: 'dlv_apply_filter',
              egg_track_id: this.filtersTemporary[i].key,
              egg_page_url: document.location.href,
              egg_page_title: document.title,
              egg_page_referrer: document.referrer,
            });
          }
          console.log('dlv_apply_filter: ' + this.filtersTemporary[i].key);
        }
      }

      this.closeFilter(this.filterItemTemporary[0].key);
      this.filtersTemporary = [];
      this.filterItemTemporary = [];
    }
    this.facetInit.emit(this.filterValues);
  }

  public resetFilter(key: string): void {
    this.openFilters.clear();
    this.activeFiltersByKey = {};
    for (let i = 0; i < this.filterValues.length; i++) {
      if (this.filterValues[i].key === key) {
        for (let j = 0; j < this.filterValues[i].facets.length; j++) {
          this.deactivateParent(this.filterValues[i].facets[j]);
          this.deactivateAllChildren(this.filterValues[i].facets[j]);
        }
      }
    }

    for (let i = 0; i < this.filterValues.length; i++) {
      if (this.filterValues[i].key === key) {
        this.filterValues.splice(i, 1);
        break;
      }
    }
    const url = new URL(window.location.href);
    url.searchParams.delete(key);
    window.history.pushState({ path: url.toString() }, '', url.toString());
    this.facetInit.emit(this.filterValues);
    this.results.emit(this.searchResults());
    this.initFilters();
  }

  public toggleNewDecorFilter(type = ''): void {
    this.activeNewDecorFilter = !this.activeNewDecorFilter;
    const url = new URL(window.location.href);

    if (this.activeNewDecorFilter) {
      url.searchParams.append('newDecors', 'true');
    } else {
      url.searchParams.delete('newDecors');
    }
    window.history.pushState({ path: url.toString() }, '', url.toString());
    this.checkFilterParamsInURL();
    this.facetInit.emit(this.filterValues);
    if (type == 'mobile') {
      this.adjustToggleMobile(url);
    }
  }

  public toggleFavoriteFilter(type = ''): void {
    this.activeFavoriteFilter = !this.activeFavoriteFilter;
    const url = new URL(window.location.href);

    if (this.activeFavoriteFilter) {
      url.searchParams.append('favorites', 'true');
    } else {
      url.searchParams.delete('favorites');
    }
    window.history.pushState({ path: url.toString() }, '', url.toString());
    this.filterByFavorites();
    this.checkFilterParamsInURL();
    this.facetInit.emit(this.filterValues);
    if (type == 'mobile') {
      this.adjustToggleMobile(url);
    }
  }

  public toggleStockFilter(type = ''): void {
    this.activeStockFilter = !this.activeStockFilter;
    const url = new URL(window.location.href);

    if (this.activeStockFilter) {
      url.searchParams.append('inStock', 'true');
    } else {
      url.searchParams.delete('inStock');
    }

    window.history.pushState({ path: url.toString() }, '', url.toString());
    this.checkFilterParamsInURL();
    this.facetInit.emit(this.filterValues);
    if (type == 'mobile') {
      this.adjustToggleMobile(url);
    }
  }

  public adjustToggleMobile(url: URL): void {
    url.searchParams.delete('openFilterMobile');
    window.history.pushState({ path: url.toString() }, '', url.toString());
    document.getElementById('filter-mobile')?.classList.add('filter-hidden');
    document.getElementById('egger-dlv-matrix')?.classList.remove('hidden');
    document.getElementById('filter-results')?.classList.remove('hidden');
    document.getElementById('additional-legend')?.classList.remove('hidden');
    document
      .getElementById('floating-button-mobile')
      ?.classList.remove('hidden');
  }

  public toggleFilter(key: string, type = 'default'): void {
    if (key == 'clear') {
      this.openFilters.clear();
    } else if (this.openFilters.has(key)) {
      this.openFilters.delete(key);
    } else {
      if (type == 'main') {
        this.filtersTemporary = [];
        this.filterItemTemporary = [];
        if (this.openFilters.has('producttype')) {
          this.openFilters.delete('producttype');
        }
        if (this.openFilters.has('decor_structuretype')) {
          this.openFilters.delete('decor_structuretype');
        }
        if (this.openFilters.has('decor_color')) {
          this.openFilters.delete('decor_color');
        }
        if (this.openFilters.has('producttype_feature')) {
          this.openFilters.delete('producttype_feature');
        }
        if (this.openFilters.has('decor_decorgroup')) {
          this.openFilters.delete('decor_decorgroup');
        }
        if (this.openFilters.has('decor_style')) {
          this.openFilters.delete('decor_style');
        }
      }
      this.openFilters.add(key);
    }
  }

  public closeFilter(key: string): void {
    this.filtersTemporary = [];
    this.filterItemTemporary = [];
    if (this.openFilters.has(key)) {
      this.openFilters.delete(key);
    }
  }

  public toggleRow(item: DlvFacetItem): void {
    if (this.openFilters.has(item.key)) {
      this.openFilters.delete(item.key);
    } else {
      this.openFilters.add(item.key);
    }
  }

  public trackByKey(index: number, item: DlvFacetItem | DcuplFacet): string {
    return item.key;
  }

  public deleteAllFilters(
    includeStock = false,
    isSearch = false,
    type = ''
  ): void {
    const url = new URL(window.location.href);
    if (url.toString().includes('openFilterMobile=true')) {
      document.getElementById('additional-legend')?.classList.remove('hidden');
      document.getElementById('dlv-header')?.classList.remove('hidden');
      url.searchParams.delete('openFilterMobile');
      window.history.pushState({ path: url.toString() }, '', url.toString());
    }
    if (url.toString().includes('producttype')) {
      url.searchParams.delete('producttype');
    }
    if (url.toString().includes('search')) {
      url.searchParams.delete('search');
    }
    if (url.toString().includes('favorites')) {
      this.activeFavoriteFilter = false;
      url.searchParams.delete('favorites');
    }
    if (url.toString().includes('decor=')) {
      url.searchParams.delete('decor');
    }
    if (url.toString().includes('decor_decorgroup')) {
      url.searchParams.delete('decor_decorgroup');
    }
    if (url.toString().includes('decor_structuretype')) {
      url.searchParams.delete('decor_structuretype');
    }
    if (url.toString().includes('decor_color')) {
      url.searchParams.delete('decor_color');
    }
    if (url.toString().includes('producttype_feature')) {
      url.searchParams.delete('producttype_feature');
    }
    if (url.toString().includes('decor_style')) {
      url.searchParams.delete('decor_style');
    }
    if (url.toString().includes('newDecors')) {
      this.activeNewDecorFilter = false;
      url.searchParams.delete('newDecors');
    }
    if (includeStock && url.toString().includes('inStock')) {
      this.activeStockFilter = false;
      url.searchParams.delete('inStock');
    }

    window.history.pushState({ path: url.toString() }, '', url.toString());
    for (let i = 0; i < this.filterValues.length; i++) {
      for (let j = 0; j < this.filterValues[i].facets.length; j++) {
        this.deactivateParent(this.filterValues[i].facets[j]);
        this.deactivateAllChildren(this.filterValues[i].facets[j]);
      }
    }

    while (url.toString().includes('inStock') && this.filterValues.length > 1) {
      for (let i = 0; i < this.filterValues.length; i++) {
        if (this.filterValues[i].key !== 'availablility') {
          this.filterValues.splice(i, 1);
        }
      }
    }

    if (!url.toString().includes('inStock')) {
      this.filterValues = [];
      this.filtersTemporary = [];
    }

    if (type == 'mobile') {
      document.getElementById('filter-mobile')?.classList.add('filter-hidden');
      document.getElementById('egger-dlv-matrix')?.classList.remove('hidden');
      document.getElementById('filter-results')?.classList.remove('hidden');
      document.getElementById('dlv-header')?.classList.remove('hidden');
      document
        .getElementById('floating-button-mobile')
        ?.classList.remove('hidden');
    }
    this.initFilters();
    if (!isSearch) {
      this.facetInit.emit(this.filterValues);
    }

    this.results.emit(this.searchResults());
  }

  private getColumns(): DlvCol[] {
    if (
      this.data?.columns &&
      this.data.columns[0].key === 'decor' &&
      this.data.columns[0].columns
    ) {
      return this.data.columns[0].columns.map((row) => {
        const dlvCol: DlvCol = {
          key: row.key,
        };
        return dlvCol;
      });
    } else {
      return [];
    }
  }
}
