import { Injectable } from '@angular/core';
import { DLV_ENVIRONMENT } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { DlvAvailabilityJsonItem } from '../types/dlvAvailabilityJsonItem.type';
import { DlvDecorJsonItem } from '../types/dlvDecorJsonItem.type';
import { StoreService } from './store.service';
import { DlvFilterItem } from '../types/dlvFilterItem.type';
import { LabelService } from 'src/app/services/label.service';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private baseUrl = DLV_ENVIRONMENT.pdfServiceUrl;
  private token = DLV_ENVIRONMENT.pdfServiceToken;
  public controller = new AbortController();
  public signal = this.controller.signal;
  private loadingState = new BehaviorSubject(false);
  currentLoadingState = this.loadingState.asObservable();

  constructor(private storeService: StoreService, public labelService: LabelService) {}

  public changeLoadingState(loading: boolean): void {
    this.loadingState.next(loading);
  }

  private getPDFServiceBasePath(): string {
    const language = this.storeService.getConfig().language;
    return `${this.baseUrl}/${language}/egger-edc/list-view?token=${this.token}`;
  }

  public async generatePDF(
    decors: DlvDecorJsonItem[],
    products: unknown[],
    availabilities: DlvAvailabilityJsonItem[],
    filters: DlvFilterItem[],
  ): Promise<void> {
    const json = {
      decors: decors,
      products: products,
      availabilities: availabilities,
      filters: filters,
    };
    this.controller = new AbortController();
    this.signal = this.controller.signal;

    const basePath = this.getPDFServiceBasePath();

    this.changeLoadingState(true);
    document.body.classList.add('overflow-hidden');
    fetch(basePath, {
      signal: this.signal,
      method: 'POST',
      body: JSON.stringify(json),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (response.ok) {
          this.changeLoadingState(false);
          document.body.classList.remove('overflow-hidden');
          return response.blob();
        } else {
          throw new Error('POST request failed');
        }
      })
      .then((pdfData) => {
        this.changeLoadingState(false);
        const objectURL = URL.createObjectURL(pdfData);
        const a = document.createElement('a');
        const timestamp = this.getCurrentTimestamp();
        a.href = objectURL;
        a.download = this.labelService.getLabel('dlv.pdf_export_filename') + '-' + timestamp + '.pdf';
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(objectURL);
        document.body.removeChild(a);
      });
  }

  public getCurrentTimestamp(): string {
    const now = new Date();

    const year = now.getFullYear();
    const month = (now.getMonth() + 1 < 10 ? '0' : '') + (now.getMonth() + 1);
    const day = (now.getDate() < 10 ? '0' : '') + now.getDate();
    const hours = (now.getHours() < 10 ? '0' : '') + now.getHours();
    const minutes = (now.getMinutes() < 10 ? '0' : '') + now.getMinutes();
    const seconds = (now.getSeconds() < 10 ? '0' : '') + now.getSeconds();

    return `${year}-${month}-${day}-at-${hours}-${minutes}-${seconds}`;
}

  public async getPDFfromServer(langCountry: string): Promise<void> {
    const pdfURL = DLV_ENVIRONMENT.pdfServerBaseUrl + langCountry + DLV_ENVIRONMENT.pdfServerBaseParams;
    fetch(pdfURL, {
      method: 'GET',
    })
      .then((response) => {
        if (response.ok) {
          this.changeLoadingState(false);
          document.body.classList.remove('overflow-hidden');
          return response.blob();
        } else {
          throw new Error('POST request failed');
        }
      })
      .then((pdfData) => {
        this.changeLoadingState(false);
        const objectURL = URL.createObjectURL(pdfData);
        const a = document.createElement('a');
        const timestamp = this.getCurrentTimestamp();
        a.href = objectURL;
        a.download = this.labelService.getLabel('dlv.pdf_export_filename') + '-' + timestamp + '.pdf';
        document.body.appendChild(a);
        a.click();

        window.URL.revokeObjectURL(objectURL);
        document.body.removeChild(a);
      });
  }

  public abortPDF(): void {
    this.controller.abort();
    this.changeLoadingState(false);
    document.body.classList.remove('overflow-hidden');
  }
}
