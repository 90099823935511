import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'dlv-app-loading-spinner-pdf',
  templateUrl: './loading-spinner-pdf.component.html',
  styleUrls: ['./loading-spinner-pdf.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerPDFComponent implements OnInit {
  @Output() pdfAbort = new EventEmitter<boolean>();

  constructor(public labelService: LabelService) {}

  ngOnInit(): void {
    document.body.classList.add('modal-open');
  }

  public abortPDF(): void {
    this.pdfAbort.emit(true);
  }

}
