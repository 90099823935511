import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LabelService } from 'src/app/services/label.service';

@Component({
  selector: 'dlv-app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorPageComponent implements OnInit {
  constructor(public labelService: LabelService) {}

  ngOnInit(): void {}

  public reload(): void {
    location.reload();
  }
}
