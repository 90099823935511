import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PivotResponse } from '@dcupl/common';
import { BehaviorSubject, Subscription } from 'rxjs';
import { DlvAvailabilityItem } from 'src/app/app.component';
import { LabelService } from 'src/app/services/label.service';
import { DlvAvailabilityPathJsonItem } from 'src/app/types/dlvAvailabilityPathJsonItem.type';
import { DlvCol } from 'src/app/types/dlvCol.type';
import { DlvDecorItem } from 'src/app/types/dlvDecorItem.type';
import { DlvFilterItem } from 'src/app/types/dlvFilterItem.type';
import { DlvPdfExportItem } from 'src/app/types/dlvPdfExportItem.types';
import { DlvProductChild } from 'src/app/types/dlvProductChild.type';
import { DlvProductDetailItem } from 'src/app/types/dlvProductDetailItem.type';
import { DlvProductItem } from 'src/app/types/dlvProductItem.type';
import { DlvRow } from 'src/app/types/dlvRow.type';

/* ATTENTION: if you change these values, you need to change the corresponding tailwind classed in the html file */
const COL_WIDTH = 48; // width of one cell in pixels                 -> w-12
const COL_HEIGHT = 64; // height of one cell in pixels               -> h-16
const HEADER_ROW_HEIGHT_CLOSED = 180; // height of header row               -> h-40
const HEADER_ROW_HEIGHT_OPENED = 416; // height of header row               -> h-104
const TITLE_COL_WIDTH_OPENED = 472; // width of first column (title of row) when open: 472px -> w-84, when closed: 164px -> w-36
const TITLE_COL_WIDTH_OPENED_MOBILE = 256; // width of first column (title of row) when open: 472px -> w-120, when closed: 164px -> w-36
const TITLE_COL_WIDTH_CLOSED = 112; // width of first column (title of row) when closed -> w-28

@Component({
  selector: 'dlv-app-pivot-table-scrollable',
  templateUrl: './pivot-table-scrollable.component.html',
  styleUrls: ['./pivot-table-scrollable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PivotTableScrollableComponent
  implements OnChanges, OnInit, OnDestroy, AfterViewInit
{
  @Input() data!: PivotResponse;
  @Input() results!: number;
  @Input() availabilities!: DlvAvailabilityItem[];
  @Input() decors!: DlvDecorItem[];
  @Input() products!: DlvProductItem[];
  @Input() filters!: DlvFilterItem[];
  @Input() productDetailsXY!: DlvProductDetailItem[];
  @Input() productDetailsZ!: DlvProductDetailItem[];
  @Input() productDetailsGlues!: DlvProductDetailItem[];
  @Input() productDetailsLayouts!: DlvProductDetailItem[];
  @Input() productDetailsLayoutModels!: DlvProductDetailItem[];
  @Input() PDFExported = false;
  @Output() modalOpened = new EventEmitter<boolean>();
  @Output() pdfExport = new EventEmitter<DlvPdfExportItem>();

  public colWidth = COL_WIDTH;
  public rows: DlvRow[] = [];
  public columns: DlvCol[] = [];
  public columnKeys: string[] = [];
  public decorRendered: DlvDecorItem[] = [];
  public productRendered: DlvRow[] = [];

  public openRows = new Set<string>();

  public scrollableHeight = '0px';
  private tableHeadingTop2?: number;

  public scrollableContainer: HTMLElement | null = null;

  /* used to make the header sticky */
  private scrollTop = 0;
  private $scrollSub = new BehaviorSubject<number>(0);
  public additionalColumns: string[] = [];
  public additionalRows: string[] = [];
  public openProduct = true;
  public openDecor = false;
  public openHeadlines = true;
  public scrollPos = 0;
  public headerRowHeight = HEADER_ROW_HEIGHT_CLOSED; // default header Row is closed
  public headerColWidth = TITLE_COL_WIDTH_OPENED; // default header Col is open
  public headerTop = this.scrollTop;

  public initialEmptyCellTop: number | undefined = undefined;

  @HostListener('window:scroll', ['$event'])
  public onScroll(): void {
    this.$scrollSub.next(window.scrollY);
  }

  @ViewChild(CdkVirtualScrollViewport)
  cdkVirtualScrollViewport?: CdkVirtualScrollViewport;

  private subscriptions = new Subscription();

  constructor(
    private cdRef: ChangeDetectorRef,
    public labelService: LabelService
  ) {}

  ngOnInit(): void {
    document.body.classList.remove('modal-open');
    if (window.innerWidth < 1090) {
      this.headerColWidth = TITLE_COL_WIDTH_OPENED_MOBILE;
    }

    this.scrollableContainer = document.getElementById(
      'matrix-scrollable-container'
    );

    window.addEventListener('resize', () => {
      if (window.innerWidth < 1090 && this.openProduct) {
        this.headerColWidth = TITLE_COL_WIDTH_OPENED_MOBILE;
      } else if (this.openProduct) {
        this.headerColWidth = TITLE_COL_WIDTH_OPENED;
      }

      this.initMinimizeButtons();
      this.scrollableWidth;
      this.scrollableHeight = `${(
        COL_HEIGHT * this.rows.length +
        this.headerRowHeight
      ).toString()}px`;
      setTimeout(() => {
        this.initMinimizeButtons();
        this.initHeaderTranslation();
      }, 100);

      this.initMinimizeButtons();
      setTimeout(() => {
        window.scrollBy(0, 1);
        this.calculateScrollBarWidth();
        this.calculateScrollPosition();
      }, 300);
    });

    this.initMinimizeButtons();

    this.scrollableHeight = `${(
      COL_HEIGHT * this.rows.length +
      this.headerRowHeight
    ).toString()}px`;

    setTimeout(() => {
      this.initMinimizeButtons();
      this.initHeaderTranslation();
      this.calculateScrollBarWidth();
      this.calculateScrollPosition();
    }, 100);

    let isScrolling: any;
    window.addEventListener(
      'scroll',
      () => {
        this.initHeaderTranslation();
        this.initMinimizeButtons();
        window.clearTimeout(isScrolling);
        this.calculateScrollPosition();
        isScrolling = setTimeout(() => {
          this.showHeader();
        }, 150);
      },
      false
    );
    document
      .querySelector('#matrix-scrollable-container')
      ?.addEventListener('scroll', () => {
        this.calculateScrollPosition();
      });
    window.addEventListener('touchend', () => {
      this.calculateScrollPosition();
    });

    window.addEventListener('wheel', (e) => {
      if (e.deltaX !== 0) {
        const products = document.querySelectorAll('.matrix-product-row');
        const subproducts = document.querySelectorAll('.dlv-matrix-subcell');
        const elements = document.querySelectorAll('.dlv-matrix-cell');
        for (let i = 0; i < products.length; i++) {
          if (products[i]) {
            const a = products[i] as HTMLElement;
            a.style.backgroundColor = '';
          }
        }
        for (let i = 0; i < subproducts.length; i++) {
          if (subproducts[i]) {
            const a = subproducts[i] as HTMLElement;
            a.style.backgroundColor = '';
          }
        }
        for (let i = 0; i < elements.length; i++) {
          if (elements[i]) {
            const a = elements[i] as HTMLElement;
            a.style.backgroundColor = '';
          }
        }
        this.calculateScrollPosition();
      }
    });

    setTimeout(() => {
      window.scrollBy(0, 1);
      this.initScrollableContainer();
    }, 300);
  }

  ngOnChanges(): void {
    this.rows = this.getRows();
    this.columns = this.getColumns();
    this.columnKeys = this.getColumnKeys();
    this.getVisibleDecors();
    this.getVisibleProducts();

    setTimeout(() => {
      this.initAdditionalColumns();
      this.initAdditionalRows();
      this.scrollableHeight = `${(
        COL_HEIGHT * this.rows.length +
        this.headerRowHeight
      ).toString()}px`;
      if (this.additionalRows.length > 0) {
        this.scrollableHeight = window.innerHeight + 'px';
      }
      this.closeAllRows();
      this.backToStartFilter();
      this.calculateScrollBarWidth();
    }, 150);

    if (this.PDFExported) {
      this.exportPDF();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.cdkVirtualScrollViewport?.elementScrolled().subscribe(() => {
      this.initHeaderTranslation(true);
    });
  }

  get inverseOfTranslation(): string {
    const viewSquare = document.getElementById('viewSquare');
    const rect = viewSquare?.getBoundingClientRect();

    if (rect !== undefined && rect.top < 0) {
      const top = (rect.top * -1).toFixed(0);
      return `${top + 1}px`;
    }
    return '0px';
  }

  public initHeaderTranslation(scrollX = false): void {
    const tableHeadings = document.querySelectorAll<HTMLElement>(
      '.table-scrollable-heading'
    );
    const viewSquare = document.getElementById('viewSquare');
    const rect = viewSquare?.getBoundingClientRect();

    const emptyCell = document.querySelector<HTMLElement>('.empty-cell');
    let emptyCellTop = 0;
    if (emptyCell) {
      emptyCellTop = emptyCell.getBoundingClientRect().top;
    }

    const decorButton = document.querySelector<HTMLElement>(
      '.decor-button-element'
    );
    const productButton = document.querySelector<HTMLElement>(
      '.product-button-element'
    );
    if (rect) {
      this.tableHeadingTop2 = emptyCellTop - rect.top;
    }

    tableHeadings.forEach((tableHeading) => {
      if (
        this.headerRowHeight === HEADER_ROW_HEIGHT_OPENED &&
        this.tableHeadingTop2 &&
        !scrollX
      ) {
        const top = this.tableHeadingTop2;
        tableHeading.style.top = rect?.top !== undefined ? `${top}px` : '0px';
      } else {
        tableHeading.style.top =
          rect?.top !== undefined ? `${this.tableHeadingTop2}px` : '0px';
      }
      if (
        this.tableHeadingTop2 !== undefined &&
        this.tableHeadingTop2 > 50 &&
        !scrollX &&
        decorButton &&
        productButton
      ) {
        tableHeading.classList.remove('opacity-transition');
        decorButton.classList.remove('opacity-transition');
        productButton.classList.remove('opacity-transition');
        tableHeading.style.opacity = '0';
        decorButton.style.opacity = '0';
        productButton.style.opacity = '0';
      }
    });

    const shadow = document.querySelector<HTMLElement>('.shadow');

    if (this.tableHeadingTop2 && shadow) {
      shadow.style.marginTop = `${
        this.headerRowHeight + this.tableHeadingTop2
      }px`;
      shadow.style.height =
        parseInt(this.scrollableHeight.split('px')[0]) -
        this.headerRowHeight -
        this.tableHeadingTop2 +
        'px';
    }
  }

  /**
   * Initializes the drag and click functionality for the scrollable container
   * The container can be dragged horizontally to scroll the content, which is detected using a mousedown event
   * The dragging ist stopped on mouseleave or mouseup event
   * For every event, the current scroll position is calculated accordiingly
   *
   * @return {void}
   *
   */
  public initScrollableContainer(): void {
    let isDown = false;
    let startX = 0;
    let scrollLeft = 0;

    if (this.scrollableContainer) {
      this.scrollableContainer?.addEventListener('mousedown', (e) => {
        isDown = true;
        if (this.scrollableContainer?.offsetLeft) {
          startX = e.pageX - this.scrollableContainer?.offsetLeft;
          scrollLeft = this.scrollableContainer?.scrollLeft;
        }
        this.scrollableContainer?.classList.add('dragging');
      });
      this.scrollableContainer?.addEventListener('mouseleave', () => {
        this.calculateScrollPosition();
        isDown = false;
        this.scrollableContainer?.classList.remove('dragging');
      });
      this.scrollableContainer?.addEventListener('mouseup', () => {
        this.calculateScrollPosition();
        isDown = false;
        this.scrollableContainer?.classList.remove('dragging');
      });
      this.scrollableContainer?.addEventListener('mousemove', (e) => {
        this.calculateScrollPosition();
        if (!isDown) return;
        e.preventDefault();
        if (this.scrollableContainer?.offsetLeft) {
          const x = e.pageX - this.scrollableContainer?.offsetLeft;
          const walk = x - startX;
          const scrollValue = scrollLeft - walk;
          setTimeout(() => {
            if (this.scrollableContainer) {
              this.scrollableContainer.scrollLeft = scrollValue;
            }
          }, 100);
        }
      });
    }
  }

  /**
   * Calculates the width of the scrollbar based on the visible width of the scrollable container
   *
   * @return {void}
   *
   */
  public calculateScrollBarWidth(): void {
    const scrollbar = document.getElementById('matrix-scrollbar');
    const fullContainerWidth = this.scrollableContainer?.scrollWidth;
    const visibleWidth = this.scrollableContainer?.clientWidth;

    if (visibleWidth && fullContainerWidth && scrollbar) {
      const width = Math.round(
        (visibleWidth / fullContainerWidth) * visibleWidth
      );
      scrollbar.style.width = `${width}px`;
    }
  }

  /**
   * Calculates the current horizontal scroll position and sets the scrollbar position accordingly
   *
   * @return {void}
   *
   */
  public calculateScrollPosition(): void {
    const scrollbar = document.getElementById('matrix-scrollbar');
    const fullContainerWidth = this.scrollableContainer?.scrollWidth;
    const scrollPosition = this.scrollableContainer?.scrollLeft;
    const visibleWidth = this.scrollableContainer?.clientWidth;

    if (scrollPosition && fullContainerWidth && scrollbar && visibleWidth) {
      const pos = Math.round(
        (scrollPosition / fullContainerWidth) * visibleWidth
      );
      if (scrollbar.offsetWidth === 10 && pos > 25) {
        scrollbar.style.marginLeft = `${pos - 5}px`;
      } else if (scrollbar.offsetWidth === 10 && pos > 35) {
        scrollbar.style.marginLeft = `${pos - 10}px`;
      } else {
        scrollbar.style.marginLeft = `${pos}px`;
      }
    }
  }

  public showHeader(): void {
    const tableHeadings = document.querySelectorAll<HTMLElement>(
      '.table-scrollable-heading'
    );

    const decorButton = document.querySelector<HTMLElement>(
      '.decor-button-element'
    );
    const productButton = document.querySelector<HTMLElement>(
      '.product-button-element'
    );

    if (decorButton && productButton) {
      tableHeadings.forEach((tableHeading) => {
        tableHeading.classList.add('opacity-transition');
        decorButton.classList.add('opacity-transition');
        productButton.classList.add('opacity-transition');
        tableHeading.style.opacity = '100';
        decorButton.style.opacity = '100';
        productButton.style.opacity = '100';
      });
    }
  }

  // used to make the header sticky
  public getHeaderTransform(): string {
    if (this.headerTop > 0) {
      return `translateY(0px)`;
    } else {
      this.headerTop = this.headerTop * -1;
      return `translateY(${this.headerTop}px)`;
    }
  }

  get scrollableWidth(): string {
    return `${(document.body.clientWidth - this.headerColWidth).toString()}px`;
  }

  public toggleTooltip(): void {
    const tooltip = document.querySelector<HTMLElement>('.tooltiptext');
    if (tooltip && tooltip?.style.visibility === 'hidden') {
      tooltip.style.visibility = 'visible';
    } else if (tooltip) {
      tooltip.style.visibility = 'hidden';
    }
  }

  private getRows(): DlvRow[] {
    const emptyRow: DlvRow[] = [];
    if (
      this.data.rows &&
      this.data.rows[0].key === 'producttypeSV' &&
      this.data.rows[0].rows
    ) {
      return this.data.rows[0].rows.map((row) => {
        const dlvRow: DlvRow = {
          key: row.key,
          children: [],
        };

        if (row.rows?.length && row.rows[0].rows?.length) {
          dlvRow.children = row.rows?.[0].rows?.map((child) => {
            return {
              key: child.key,
              children: emptyRow,
            };
          });
        }

        return dlvRow;
      });
    } else {
      return [];
    }
  }
  private getColumns(): DlvCol[] {
    if (
      this.data.columns &&
      this.data.columns[0].key === 'decor' &&
      this.data.columns[0].columns
    ) {
      return this.data.columns[0].columns.map((row) => {
        const dlvCol: DlvCol = {
          key: row.key,
        };
        return dlvCol;
      });
    } else {
      return [];
    }
  }

  private getColumnKeys(): string[] {
    if (
      this.data.columns &&
      this.data.columns[0].key === 'decor' &&
      this.data.columns[0].columns
    ) {
      return this.data.columns[0].columns.map((row) => {
        const dlvCol: string = row.key;
        return dlvCol;
      });
    } else {
      return [];
    }
  }

  private getVisibleDecors(): void {
    this.decorRendered = [];
    for (let i = 0; i < this.decors.length; i++) {
      if (this.columnKeys.includes(this.decors[i].key)) {
        this.decorRendered.push(this.decors[i]);
      }
    }
  }

  private getLabelForProductDimensionXY(key: string): string {
    const index = this.getIndexForData(key, 'XY');
    return this.productDetailsXY[index]?.label || '';
  }

  private getLabelForProductDimensionZ(key: string): string {
    const index = this.getIndexForData(key, 'Z');
    return this.productDetailsZ[index]?.label || '';
  }

  private sortByXY(array: DlvRow[]): DlvRow[] {
    let x = '';
    let y = '';
    return array.sort((a: DlvRow, b: DlvRow) => {
      if (
        this.productDetailsXY[
          this.getIndexForData(a.key.split('__')[1], 'XY')
        ] &&
        this.productDetailsXY[this.getIndexForData(a.key.split('__')[1], 'XY')]
          .label
      ) {
        x =
          this.productDetailsXY[
            this.getIndexForData(a.key.split('__')[1], 'XY')
          ].label;
      }
      if (
        this.productDetailsXY[
          this.getIndexForData(b.key.split('__')[1], 'XY')
        ] &&
        this.productDetailsXY[this.getIndexForData(b.key.split('__')[1], 'XY')]
          .label
      ) {
        y =
          this.productDetailsXY[
            this.getIndexForData(b.key.split('__')[1], 'XY')
          ].label;
      }
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  private sortByZ(array: DlvRow[]): DlvRow[] {
    return array.sort((a: DlvRow, b: DlvRow) => {
      let x = 0;
      let y = 0;
      if (
        this.productDetailsZ[this.getIndexForData(a.key.split('__')[2], 'Z')] &&
        this.productDetailsZ[this.getIndexForData(a.key.split('__')[2], 'Z')]
          .label
      ) {
        x = parseFloat(
          this.productDetailsZ[
            this.getIndexForData(a.key.split('__')[2], 'Z')
          ].label.replace(',', '.')
        );
      }
      if (
        this.productDetailsZ[this.getIndexForData(b.key.split('__')[2], 'Z')] &&
        this.productDetailsZ[this.getIndexForData(b.key.split('__')[2], 'Z')]
          .label
      ) {
        y = parseFloat(
          this.productDetailsZ[
            this.getIndexForData(b.key.split('__')[2], 'Z')
          ].label.replace(',', '.')
        );
      }
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  private sortByQuantityNumber(array: DlvRow[]): DlvRow[] {
    return array.sort(function (a: DlvRow, b: DlvRow) {
      const x = parseFloat(a.key.split('__')[3]);
      const y = parseFloat(b.key.split('__')[3]);
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  private sortByQuantityValue(array: DlvRow[]): DlvRow[] {
    return array.sort(function (a: DlvRow, b: DlvRow) {
      const x = a.key.split('__')[4];
      const y = b.key.split('__')[4];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  private getVisibleProducts(): void {
    this.productRendered = [];
    for (let i = 0; i < this.products.length; i++) {
      for (let j = 0; j < this.rows.length; j++) {
        if (this.rows[j].key == this.products[i].key) {
          this.productRendered.push(this.rows[j]);
        }
      }
    }

    for (let i = 0; i < this.productRendered.length; i++) {
      this.sortByQuantityValue(this.productRendered[i].children);
      this.sortByQuantityNumber(this.productRendered[i].children);
      this.sortByZ(this.productRendered[i].children);
      this.sortByXY(this.productRendered[i].children);
    }
  }

  public getPivotValue(path: string[]): string {
    const pathAsString = path.join('__');
    const value = (this.data as any).paths[pathAsString];
    if (value) {
      return value?.max ? value.max : value;
    }
    return '';
  }

  public getAvailabilityIconColorClass(path: string[]): string {
    const value = this.getPivotValue(path);
    if (value) {
      switch (parseInt(value)) {
        case 5:
        case 4:
        case 3: // red
          return 'text-[#e31937]';
        case 2: // black
          return 'text-black';
      }
    }
    return '';
  }

  public getAvailabilityIcon(path: string[]): string {
    const value = this.getPivotValue(path);
    if (value) {
      switch (parseInt(value)) {
        case 5:
          return 'circle-full'; //red
        case 4:
          return 'circle-half'; // red
        case 3: // red
        case 2: // black
          return 'circle-outline';
      }
    }
    return '';
  }

  public getAvailabilityLabel(path: string[]): string {
    const value = this.getPivotValue(path);
    if (value) {
      switch (parseInt(value)) {
        case 5:
          return this.availabilities[0].label;
        case 4:
          return this.availabilities[1].label;
        case 3:
          return this.availabilities[2].label;
        case 2: // black
          return this.availabilities[3].label;
      }
    }
    return '';
  }

  public trackByKey(index: number, item: DlvRow | DlvCol): string {
    return item.key;
  }

  public highlightAllClasses(className: string, classChild = ''): void {
    let elements;
    if (classChild !== '') {
      elements = document.querySelectorAll<HTMLElement>(
        `[data-row-id="${className}-${classChild}"]`
      );
    } else {
      elements = document.querySelectorAll<HTMLElement>(
        `[data-row-id="${className}"]`
      );
    }

    for (let i = 0; i < elements.length; i++) {
      const parent = elements[i].parentElement as HTMLElement;
      if (
        !this.scrollableContainer?.classList.contains('dragging') &&
        window.innerWidth >= 768
      ) {
        if (!parent.classList.contains('example-item')) {
          parent.style.backgroundColor = '#fce8eb';
        }
      }
    }
  }

  public removeHighlightAllClasses(className: string, classChild = ''): void {
    let elements;
    if (classChild !== '') {
      elements = document.querySelectorAll<HTMLElement>(
        `[data-row-id="${className}-${classChild}"]`
      );
    } else {
      elements = document.querySelectorAll<HTMLElement>(
        `[data-row-id="${className}"]`
      );
    }

    for (let i = 0; i < elements.length; i++) {
      const parent = elements[i].parentElement as HTMLElement;
      parent.style.backgroundColor = '';
    }
  }

  public toggleRow(row: DlvRow): void {
    if (this.openRows.has(row.key)) {
      this.openRows.delete(row.key);
    } else {
      this.openRows.add(row.key);
    }
    let childRowHeight = 0;
    this.rows.forEach((curRow) => {
      if (this.openRows.has(curRow.key)) {
        childRowHeight += curRow.children.length * COL_HEIGHT;
      }
    });

    this.scrollableHeight = `${(
      COL_HEIGHT * (this.rows.length + this.additionalRows.length) +
      this.headerRowHeight +
      childRowHeight
    ).toString()}px`;
    this.initAdditionalRows();
  }

  public onBackToStartClicked(): void {
    this.scrollTop = 0;
    setTimeout(() => {
      this.cdRef.detectChanges();
      document.getElementById('matrix-top')?.scrollIntoView(true);
      this.cdkVirtualScrollViewport?.scrollToIndex(0, 'smooth');
    }, 25);
    setTimeout(() => {
      window.scrollBy(0, 10);
      const scrollbar = document.getElementById('matrix-scrollbar');
      if (scrollbar) {
        scrollbar.style.marginLeft = `0px`;
      }
    }, 500);
  }

  public backToStartFilter(): void {
    this.scrollTop = 0;
    setTimeout(() => {
      this.cdRef.detectChanges();
      this.cdkVirtualScrollViewport?.scrollToIndex(0, 'smooth');
    }, 25);
    setTimeout(() => {
      window.scrollBy(0, 1);
    }, 100);
  }

  public closeAllRows(): void {
    for (let i = 0; i < this.productRendered.length; i++) {
      if (this.openRows.has(this.productRendered[i].key)) {
        this.toggleRow(this.productRendered[i]);
      }
    }
  }

  public openModal(): void {
    document.body.classList.add('modal-open');
    this.modalOpened.emit(true);
  }

  public getIndexForData(dataKey: string, type: string): number {
    let index = 0;

    if (dataKey) {
      if (type === 'decor') {
        index = this.decors.findIndex((x) => x.key === dataKey);
      }
      if (type === 'product') {
        index = this.products.findIndex((x) => x.key === dataKey);
      }
      if (type === 'XY') {
        index = this.productDetailsXY.findIndex((x) => x.key === dataKey);
      }
      if (type === 'Z') {
        index = this.productDetailsZ.findIndex((x) => x.key === dataKey);
      }
      if (type === 'glues') {
        index = this.productDetailsGlues.findIndex((x) => x.key === dataKey);
      }
      if (type === 'models') {
        index = this.productDetailsLayoutModels.findIndex(
          (x) => x.key === dataKey
        );
      }
      if (type === 'layouts') {
        index = this.productDetailsLayouts.findIndex((x) => x.key === dataKey);
      }
    }
    return index;
  }

  public toggleMinimizeHeadline(): void {
    this.openProduct = !this.openProduct;
    if (window.innerWidth >= 1090) {
      this.headerColWidth =
        this.headerColWidth === TITLE_COL_WIDTH_OPENED
          ? TITLE_COL_WIDTH_CLOSED
          : TITLE_COL_WIDTH_OPENED;
    } else {
      this.headerColWidth =
        this.headerColWidth === TITLE_COL_WIDTH_OPENED_MOBILE
          ? TITLE_COL_WIDTH_CLOSED
          : TITLE_COL_WIDTH_OPENED_MOBILE;
    }
    this.cdRef.detectChanges();
    this.checkForClosedHeadlines();

    setTimeout(() => {
      this.initMinimizeButtons();
      this.initAdditionalColumns();
    }, 10);
  }

  public toggleMinimizeDecor(): void {
    this.openDecor = !this.openDecor;
    const table = document.querySelector<HTMLElement>(
      '.cdk-virtual-scroll-viewport'
    );
    const tableHeight = document.querySelector<HTMLElement>(
      '.cdk-virtual-scroll-viewport'
    )?.style.height;

    // switch headerRowHeight
    this.headerRowHeight =
      this.headerRowHeight === HEADER_ROW_HEIGHT_CLOSED
        ? HEADER_ROW_HEIGHT_OPENED
        : HEADER_ROW_HEIGHT_CLOSED;
    this.checkForClosedHeadlines();

    setTimeout(() => {
      if (
        this.headerRowHeight === HEADER_ROW_HEIGHT_OPENED &&
        table &&
        tableHeight
      ) {
        const height: number = parseInt(tableHeight.split('px')[0]);
        const heightNew = height + 300;
        table.style.height = `${heightNew}px`;
      } else if (
        this.headerRowHeight === HEADER_ROW_HEIGHT_CLOSED &&
        table &&
        tableHeight
      ) {
        const height: number = parseInt(tableHeight.split('px')[0]);
        const heightNew = height - 300;
        table.style.height = `${heightNew}px`;
      }
      this.initMinimizeButtons();
      this.initHeaderTranslation(true);
      window.scrollTo(window.scrollX, window.scrollY + 1);
      this.scrollableHeight = `${(
        COL_HEIGHT * (this.rows.length + this.additionalRows.length) +
        this.headerRowHeight
      ).toString()}px`;
    }, 100);
    this.initAdditionalRows();
    this.scrollableHeight = `${(
      COL_HEIGHT * (this.rows.length + this.additionalRows.length) +
      this.headerRowHeight
    ).toString()}px`;
  }

  public checkForClosedHeadlines(): void {
    if (!this.openDecor || !this.openProduct) {
      this.openHeadlines = false;
    } else {
      this.openHeadlines = true;
    }
  }

  public initMinimizeButtons(): void {
    const emptyCell = document.getElementsByClassName('empty-cell');
    const emptyCellTop = emptyCell[0].getBoundingClientRect().top;

    let tableWidth =
      document.querySelector<HTMLElement>('.dlv-table')?.offsetWidth;
    if (tableWidth && tableWidth > window.innerWidth) {
      tableWidth = window.innerWidth;
    }

    const decorButton: HTMLElement | null = document.querySelector(
      '.toggle-decor-button'
    );
    const productButton: HTMLElement | null = document.querySelector(
      '.toggle-product-button'
    );
    const emptyCellWidth =
      document.querySelector<HTMLElement>('.empty-cell')?.offsetWidth;
    const emptyCellHeight =
      document.querySelector<HTMLElement>('.empty-cell')?.offsetHeight;

    decorButton?.classList.remove('hidden');

    if (emptyCellTop !== undefined) {
      if (emptyCellWidth && emptyCellHeight && productButton && decorButton) {
        productButton.style.left = `${emptyCellWidth - 18}px`;
        decorButton.style.left = `${
          (window.innerWidth - emptyCellWidth) / 2 + emptyCellWidth - 15
        }px`;
        if (
          this.tableHeadingTop2 &&
          this.tableHeadingTop2 > 0 &&
          productButton &&
          decorButton
        ) {
          decorButton.style.top = `${
            emptyCellHeight + this.tableHeadingTop2 - 15
          }px`;
          if (
            emptyCellHeight === HEADER_ROW_HEIGHT_OPENED &&
            productButton &&
            decorButton
          ) {
            productButton.style.top = `${
              this.tableHeadingTop2 +
              emptyCellHeight +
              window.innerHeight / 2 -
              200
            }px`;
          } else {
            productButton.style.top = `${
              this.tableHeadingTop2 +
              emptyCellHeight +
              window.innerHeight / 2 -
              100
            }px`;
          }
        } else {
          decorButton.style.top = `${emptyCellHeight - 15}px`;
          productButton.style.top = `${
            (window.innerHeight - emptyCellTop - emptyCellHeight) / 2 +
            emptyCellHeight
          }px`;
        }
      }
    }
    const shadow = document.querySelector<HTMLElement>('.shadow');

    if (shadow) {
      shadow.style.marginTop = `${this.headerRowHeight}px`;
      shadow.style.height =
        parseInt(this.scrollableHeight.split('px')[0]) -
        this.headerRowHeight +
        'px';
    }

    this.initAdditionalColumns();
    this.initAdditionalRows();
  }

  public initAdditionalColumns(): void {
    const emptyCellWidth =
      document.querySelector<HTMLElement>('.empty-cell')?.offsetWidth;
    let actualTableWidth = 0;
    if (emptyCellWidth) {
      actualTableWidth = this.decorRendered.length * COL_WIDTH + emptyCellWidth;
    }

    this.decorRendered.length;
    if (actualTableWidth != undefined) {
      const difference = window.innerWidth - actualTableWidth;
      this.additionalColumns = [];
      if (difference > 0) {
        const columns = Math.ceil(difference / COL_WIDTH) + 4;
        for (let i = 0; i < columns; i += 1) {
          this.additionalColumns.push('');
        }
      }
    }
  }

  public initAdditionalRows(): void {
    const emptyCellHeight =
      document.querySelector<HTMLElement>('.empty-cell')?.offsetHeight;
    if (emptyCellHeight) {
      let childRowHeight = 0;
      this.rows.forEach((curRow) => {
        if (this.openRows.has(curRow.key)) {
          childRowHeight += curRow.children.length * COL_HEIGHT;
        }
      });
      const difference =
        window.innerHeight -
        this.productRendered.length * COL_HEIGHT -
        childRowHeight -
        emptyCellHeight;

      this.additionalRows = [];
      if (difference > 0) {
        const rows = Math.ceil(difference / COL_HEIGHT);
        for (let i = 0; i < rows; i += 1) {
          this.additionalRows.push('');
        }
      }
      this.scrollableHeight = `${(
        COL_HEIGHT * (this.rows.length + this.additionalRows.length) +
        this.headerRowHeight +
        childRowHeight
      ).toString()}px`;
    }
  }

  public getAvailabilityPath(path: string[]): string {
    const value = this.getPivotValue(path);
    if (value) {
      switch (parseInt(value)) {
        case 5:
          return 'https://pimmedia.egger.com/l/quickinfo/red_dot_new/s/deliveryStatement/f/40x40/8814897463326';
        case 4:
          return 'https://pimmedia.egger.com/l/quickinfo/red_halfmoon_new/s/deliveryStatement/f/40x40/8805125324830';
        case 3:
          return 'https://pimmedia.egger.com/l/quickinfo/red_circle_new/s/deliveryStatement/f/40x40/8805125029918';
        case 2:
          return 'https://pimmedia.egger.com/l/quickinfo/black_circle.gif/s/deliveryStatement/f/default/8814115356702';
      }
    }
    return '';
  }

  public isTouchDevice(): boolean {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
  }

  public exportPDF(): void {
    const prods: DlvProductItem[] = [];
    const prodChildren: DlvProductChild[] = [];
    const finalProductData: unknown[] = [];
    for (let i = 0; i < this.productRendered.length; i++) {
      for (let j = 0; j < this.products.length; j++) {
        if (this.productRendered[i].key === this.products[j].key) {
          prods.push(this.products[j]);
          for (let k = 0; k < this.productRendered[i].children.length; k++) {
            const availabilitiesProducts: DlvAvailabilityPathJsonItem[] = [];
            for (let l = 0; l < this.decorRendered.length; l++) {
              availabilitiesProducts.push({
                path: this.getAvailabilityPath([
                  'root',
                  'producttypeSV',
                  this.productRendered[i].key,
                  'generated_pivoting_value',
                  this.productRendered[i].children[k].key,
                  'decor',
                  this.decorRendered[l].key,
                ]),
              });
            }
            prodChildren.push({
              key: this.productRendered[i].children[k].key,
              parent: this.productRendered[i].key,
              availabilities: availabilitiesProducts,
            });
          }
        }
      }
    }

    for (let i = 0; i < prods.length; i++) {
      finalProductData.push({
        isProductType: true,
        path: prods[i].parent ? prods[i].parent.icon?.split('@')[0] : '',
        name: prods[i].label,
      });
      for (let j = 0; j < prodChildren.length; j++) {
        if (prods[i].key === prodChildren[j].parent) {
          finalProductData.push({
            xy: this.getLabelForProductDimensionXY(
              prodChildren[j].key?.split('__')[1]
            ),
            z: this.getLabelForProductDimensionZ(
              prodChildren[j].key?.split('__')[2]
            ),
            glue: '',
            layoutId: prodChildren[j].key?.split('__')[5],
            quantity:
              prodChildren[j].key?.split('__')[3] +
              ' ' +
              prodChildren[j].key?.split('__')[4],
            availabilities: prodChildren[j].availabilities,
            model: '',
          });
        }
      }
    }

    const exportData: DlvPdfExportItem = {
      decors: this.decorRendered,
      products: finalProductData,
      availabilities: this.availabilities,
      filters: this.filters
    };
    if (window._jts && window._jts.push) {
      window._jts.push({
        track: 'event',
        group: 'cta',
        egg_track_id: 'pdf_export',
        egg_page_url: document.location.href,
        egg_page_title: document.title,
        egg_page_referrer: document.referrer,
      });
    }
    console.log('dlv_export_pdf: ', 'PDF Export');

    this.pdfExport.emit(exportData);
  }
}
