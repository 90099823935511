import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'dlv-app-pdf-button',
  templateUrl: './pdf-button.component.html',
  styleUrls: ['./pdf-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PDFButtonComponent implements OnInit, OnChanges {
  @Output() pdfExport = new EventEmitter<boolean>();
  @Input() public filterResults!: number;

  constructor() {}

  ngOnInit(): void {
    setTimeout(() => {
      if (this.filterResults !== 0) {
        document
        .getElementById('pdf-button')
        ?.classList.remove('hidden');
        if (this.isFilterVisible()) {
          document
            .getElementById('pdf-button')
            ?.classList.add('pdf-button-bottom');
        } else {
          document
            .getElementById('pdf-button')
            ?.classList.remove('pdf-button-bottom');
        }
      } else {
        document
        .getElementById('pdf-button')
        ?.classList.add('hidden');
      }
    }, 100);

    addEventListener('scroll', () => {
      if (this.isFilterVisible()) {
        document
          .getElementById('pdf-button')
          ?.classList.add('pdf-button-bottom');
      } else {
        document
          .getElementById('pdf-button')
          ?.classList.remove('pdf-button-bottom');
      }
    });
  }

  ngOnChanges(): void {
    if (this.filterResults !== 0) {
      document
      .getElementById('pdf-button')
      ?.classList.remove('hidden');
    } else {
      document
      .getElementById('pdf-button')
      ?.classList.add('hidden');
    }
}

  public exportPDF(): void {
    this.pdfExport.emit(true);
  }

  public isFilterVisible(): boolean {
    if (window.innerWidth < 768) {
      return false;
    } else {
      const element = document.getElementById('filter-desktop');
      if (!element) return false;
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= -element.offsetHeight &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  }
}
